import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import DateFrom from './DateFrom';
import DateTo from './DateTo';
import DateType from './DateType';
import PaymentNumber from './PaymentNumber';
import InvoiceType from './InvoiceType';

import { getCashFlow, setCashFlowData, setCashFlowQuery } from '../../actions/cashFlow.axtion.creators';
import moment from 'moment';

const Filters = () => {
  const dispatch = useDispatch();
  const requestParams = useSelector((state) => state.cashFlow.requestParams);
  const methods = useForm({
    defaultValues: {
      PeriodFrom: moment(requestParams.query.PeriodFrom).toDate(),
      PeriodTo: moment(requestParams.query.PeriodTo).toDate()
    }
  });
  const periodFrom = methods.watch('PeriodFrom');
  const periodTo = methods.watch('PeriodTo');

  const onSubmit = async (data) => {
    const query = {
      ...requestParams.query,
      PeriodFrom: moment(data.PeriodFrom).format('YYYY-MM-DD'),
      PeriodTo: moment(data.PeriodTo).format('YYYY-MM-DD')
    };

    const responseData = await getCashFlow({
      ...requestParams,
      query
    });

    dispatch(setCashFlowData(responseData));
    dispatch(setCashFlowQuery(query));
    methods.reset(data);
  };

  return (
    <div className="cash-flow-filters">
      <div className="card mb-0 h-full overflow-y-auto">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-column gap-3">
              <div className="flex flex-column sm:flex-row md:flex-column gap-3">
                {/* <DateType /> */}
                <div className="flex flex-row sm:flex-row md:flex-column gap-3 w-full">
                  <DateFrom />
                  <DateTo />
                </div>
              </div>

              {/* <div className="flex flex-column sm:flex-row md:flex-column gap-3">
            <PaymentNumber />
            <InvoiceType />
          </div> */}

              <div className="flex gap-2 mt-2">
                <Button
                  label="Search"
                  icon="pi pi-search"
                  className="w-full"
                  disabled={!periodFrom || !periodTo}
                  loading={methods.formState.isSubmitting}
                />
                {/* <Button
                  type="button"
                  label="Clear"
                  icon="pi pi-filter-slash"
                  className="w-full"
                  outlined
                  onClick={() => dispatch(resetCashFlowState())}
                /> */}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Filters;
