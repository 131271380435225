import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChartCard from './ChartCard';
import ChartCardTitle from './ChartCardTitle';
import { Chart } from 'primereact/chart';
import { RadioButton } from 'primereact/radiobutton';

import { colors } from '../../../config/stylesConfig';
import { invoiceTypes } from '../../../claims/helpers/mapper';
import { currencyFormat } from '../../../utils/currencyFormat';
import { isChartDataEmpty } from '../../helpers/isChartsDataEmpty';
import { setClaimsDialogVisibility } from '../../actions/cashFlow.axtion.creators';
import moment from 'moment';

const barColors = {
  M: colors.help,
  W: colors.info,
  R: colors.infoDark,
  I: colors.success,
  P: colors.warning,
  E: colors.danger
};

const amountTypes = {
  billed: 'TotalBilled',
  paid: 'TotalPaid'
};

export default function ChartByType() {
  const dispatch = useDispatch();
  const [amountType, setAmountType] = useState(amountTypes.billed);
  const loading = useSelector((state) => state.cashFlow.cashFlowLoading);
  const requestParams = useSelector((state) => state.cashFlow.requestParams);
  const data = useSelector((state) => state.cashFlow.data);
  const dateFormat = 'MMM Do, YYYY';

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  const options = useMemo(() => {
    return {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const dataset = tooltipItem.dataset;
              return dataset.tooltipLabels[tooltipItem.dataIndex]; // Use tooltipLabels from dataset
            }
          }
        },
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        }
      },
      onClick: (event, activeElements) => {
        // if (activeElements.length > 0) {
        //   const chart = activeElements[0].element.$context.chart;
        //   const datasetIndex = activeElements[0].datasetIndex; // Get index of dataset
        //   const dataIndex = activeElements[0].index; // Get index of the bar
        //   const clickedDate = chart.data.labels[dataIndex]; // Date label for the clicked bar
        //   const invoiceType = chart.data.datasets[datasetIndex].invoiceType;
        //   dispatch(
        //     setClaimsDialogVisibility({
        //       visible: true,
        //       periodFrom: moment(clickedDate, dateFormat),
        //       requestParams: {
        //         ...requestParams,
        //         query: {
        //           ...requestParams.query,
        //           PeriodFrom: moment(clickedDate, dateFormat).format('YYYY-MM-DD'),
        //           PeriodTo: moment(clickedDate, dateFormat).format('YYYY-MM-DD'),
        //           Period: '7',
        //           SubmissionPeriodFrom: '2000-01-01', // Hack
        //           SubmissionPeriodTo: moment().format('YYYY-MM-DD'),
        //           SubmissionPeriod: '7',
        //           RecordType: '10', // Teleplan claims
        //           InvoiceType: invoiceType
        //         }
        //       }
        //     })
        //   );
        // }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textColor, textColorSecondary, surfaceBorder]);

  const chartData = useMemo(() => {
    if (!data || !data?.ListByType) return null;

    // First, generate labels from all datasets combined, then sort by date.
    const allLabels = Object.keys(data?.ListByType)
      .flatMap((key) => data?.ListByType[key].map((item) => moment(item.PeriodStart).format(dateFormat)))
      // Ensure labels are unique
      .filter((value, index, self) => self.indexOf(value) === index)
      // Sort the labels by date using moment
      .sort((a, b) => moment(a, dateFormat).toDate() - moment(b, dateFormat).toDate());

    const datasets = Object.keys(data?.ListByType).map((key) => {
      // const totalBilled = data?.ListByType?.[key]?.reduce(
      //   (acc, item) => acc + (amountType === amountTypes.billed ? item.TotalBilled : item.TotalPaid),
      //   0
      // );
      const totalBilled = data?.TotalBilled;
      const invoiceType = invoiceTypes.find((i) => i.value.toUpperCase() === key.toUpperCase())?.label;
      const label = `${invoiceType} (${currencyFormat(totalBilled)})`; // Add total value to label for legend

      // Map data for each label with corresponding billed/paid value and claim count
      const dataset = data?.ListByType?.[key].map((item) => ({
        date: moment(item.PeriodStart).format(dateFormat),
        value: item[amountType],
        claimsCount: item.TotalCount // Store claims count for each bar
      }));

      const filteredData = allLabels.map((label) => {
        const match = dataset.find((d) => d.date === label);
        return match ? match.value : 0; // Show 0 if there's no matching date
      });

      const claimsData = allLabels.map((label) => {
        const match = dataset.find((d) => d.date === label);
        return match ? match.claimsCount : 0; // Show 0 if there's no matching claim count
      });

      // Create tooltipLabel for each bar
      const tooltipLabels = allLabels.map((label, index) => {
        return `${invoiceType} ${currencyFormat(filteredData[index])}, Claim(s): ${claimsData[index]}`;
      });

      return {
        type: 'bar',
        label, // This label now includes the total billed/paid value (for the legend)
        backgroundColor: barColors[key.toUpperCase()],
        data: filteredData, // Billed/Paid values for the bars
        claimsCount: claimsData, // Claims count for the bars
        tooltipLabels, // Tooltip labels for each bar
        invoiceType
      };
    });

    return {
      labels: allLabels,
      datasets
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, amountType]);

  const title = (
    <div className="flex flex-column sm:flex-row justify-content-between align-items-start w-full gap-3">
      <ChartCardTitle title="Revenue by Insurer (CAD)" />
      <div className="flex flex-wrap gap-3">
        <div className="flex align-items-center">
          <RadioButton
            inputId="billed"
            name="amount"
            value={amountTypes.billed}
            onChange={(e) => setAmountType(e.value)}
            checked={amountType === amountTypes.billed}
          />
          <label htmlFor="billed" className="ml-2">
            Billed
          </label>
        </div>
        <div className="flex align-items-center">
          <RadioButton
            inputId="paid"
            name="amount"
            value={amountTypes.paid}
            onChange={(e) => setAmountType(e.value)}
            checked={amountType === amountTypes.paid}
          />
          <label htmlFor="paid" className="ml-2">
            Paid
          </label>
        </div>
      </div>
    </div>
  );

  return (
    <ChartCard title={title} loading={loading} isChartDataEmpty={isChartDataEmpty(chartData)}>
      <Chart type="bar" data={chartData} options={options} />
    </ChartCard>
  );
}
