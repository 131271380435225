import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ChartCard from './ChartCard';
import ChartCardTitle from './ChartCardTitle';
import { Chart } from 'primereact/chart';
import { RadioButton } from 'primereact/radiobutton';

import { colorsCollection } from '../../../../themes/colorsCollection';
import { isChartDataEmpty } from '../../helpers/isChartsDataEmpty';
import { currencyFormat } from '../../../utils/currencyFormat';
import moment from 'moment';

export default function ChartByPayeeNumber() {
  const data = useSelector((state) => state.cashFlow.data);
  const loading = useSelector((state) => state.cashFlow.cashFlowLoading);
  const [chartViewType, setChartViewType] = useState('bars'); // "bars", "stackedBars", "line"
  const dateFormat = 'MMM Do, YYYY';

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  const options = useMemo(() => {
    const isStacked = chartViewType === 'stackedBars';
    return {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const dataset = tooltipItem.dataset;
              return dataset.tooltipLabels[tooltipItem.dataIndex]; // Use tooltipLabels from dataset
            }
          }
        },
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          stacked: isStacked, // Stack only when "Stacked Bars" is selected
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        },
        y: {
          stacked: isStacked, // Stack only when "Stacked Bars" is selected
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartViewType, textColor, textColorSecondary, surfaceBorder]);

  const chartData = useMemo(() => {
    if (!data || !data?.ListByPayee) return null;

    // First, generate labels from all datasets combined.
    const allLabels = Object.keys(data?.ListByPayee)
      .flatMap((key) => data?.ListByPayee[key].map((item) => moment(item.PeriodStart).format(dateFormat)))
      // Ensure labels are unique
      .filter((value, index, self) => self.indexOf(value) === index)
      // Sort the labels by date using moment
      .sort((a, b) => moment(a, dateFormat).toDate() - moment(b, dateFormat).toDate());

    // Extract color values from colorsCollection array
    const colorValues = colorsCollection.map((colorObj) => Object.values(colorObj)[0]);

    const datasets = Object.keys(data?.ListByPayee).map((key, index) => {
      const totalBilled = data?.ListByPayee?.[key]?.reduce((acc, item) => acc + item.TotalBilled, 0);
      const totalCount = data?.ListByPayee?.[key]?.reduce((acc, item) => acc + item.TotalCount, 0);

      // Now ensure that we map data only for items that match the corresponding label's date
      const dataset = data?.ListByPayee?.[key].map((item) => ({
        date: moment(item.PeriodStart).format(dateFormat),
        value: item.TotalBilled,
        claimsCount: item.TotalCount // Store claims count for each bar
      }));

      const filteredData = allLabels.map((label) => {
        const match = dataset.find((d) => d.date === label);
        return match ? match.value : 0; // Show 0 if there's no matching date
      });

      const claimsData = allLabels.map((label) => {
        const match = dataset.find((d) => d.date === label);
        return match ? match.claimsCount : 0; // Show 0 if there's no matching claim count
      });

      // Create tooltipLabel for each bar
      const tooltipLabels = allLabels.map((label, i) => {
        return `${key} ${currencyFormat(filteredData[i])}, Claim(s): ${claimsData[i]}`;
      });

      return {
        label: `${key} (${currencyFormat(totalBilled)})`, // This label now includes the total billed value (for the legend)
        backgroundColor: colorValues[index % colorValues.length],
        borderColor: colorValues[index % colorValues.length],
        data: filteredData, // Billed values for the bars
        claimsCount: claimsData, // Claims count for the bars
        tooltipLabels, // Tooltip labels for each bar
        type: chartViewType === 'line' ? 'line' : 'bar', // Set the type dynamically (bar or line)
        tension: chartViewType === 'line' ? 0.4 : 0 // Smooth lines for line type
      };
    });

    return {
      labels: allLabels,
      datasets
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartViewType]);

  const title = (
    <div className="flex flex-column sm:flex-row justify-content-between align-items-start w-full gap-3">
      <ChartCardTitle title="Paid Teleplan Claims by Payee Number (CAD)" />
      <div className="flex flex-wrap gap-3">
        <div className="flex align-items-center">
          <RadioButton inputId="bars" name="type" value="bars" onChange={(e) => setChartViewType(e.value)} checked={chartViewType === 'bars'} />
          <label htmlFor="bars" className="ml-2">
            Bars
          </label>
        </div>
        <div className="flex align-items-center">
          <RadioButton
            inputId="stackedBars"
            name="type"
            value="stackedBars"
            onChange={(e) => setChartViewType(e.value)}
            checked={chartViewType === 'stackedBars'}
          />
          <label htmlFor="stackedBars" className="ml-2">
            Stacked Bars
          </label>
        </div>
        <div className="flex align-items-center">
          <RadioButton inputId="line" name="type" value="line" onChange={(e) => setChartViewType(e.value)} checked={chartViewType === 'line'} />
          <label htmlFor="line" className="ml-2">
            Line
          </label>
        </div>
      </div>
    </div>
  );

  return (
    <ChartCard title={title} loading={loading} isChartDataEmpty={isChartDataEmpty(chartData)}>
      <Chart type={chartViewType === 'line' ? 'line' : 'bar'} data={chartData} options={options} />
    </ChartCard>
  );
}
