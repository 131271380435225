import React from 'react';
import { useSelector } from 'react-redux';

import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import Bureau from './Bureau/Bureau';
import Logout from './Logout/Logout';
import Redirect from './Redirect/Redirect';
import BackdoorSignIn from './BackdoorSignIn/BackdoorSignIn';
import Verification from './Verification/Verification';
import qs from 'qs';

import './Auth.scss';
import PasswordUpdate from './PasswordUpdate/PasswordUpdate';

const Auth = () => {
  const user = useSelector((state) => state.user);
  const query_str = window.location.search ? window.location.search.replace('?', '') : '';
  console.log('query_str',query_str);
  query_str && console.log('query_str',qs.parse(query_str));
  const { code } = qs.parse(query_str);
  console.log('code',code);
  const sso = query_str.indexOf('key') > -1 && query_str.indexOf('DCNGuid') > -1;
  console.log('sso',sso);
  debugger;
  return (
    <>
      {user.loginView === 'login' && code && <BackdoorSignIn />}
      {user.loginView === 'login' && !sso && !code && <SignIn />}
      {user.loginView === 'register' && <SignUp />}
      {user.loginView === 'verify' && <Verification />}
      {user.loginView === 'updatePassword' && <PasswordUpdate />}
      {user.loginView === 'bureau' && <Bureau />}
      {user.loginView === 'logout' && <Logout />}
      {(user.loginView === 'redirect' || (user.loginView === 'login' && sso)) && <Redirect />}
    </>
  );
};

export default Auth;
