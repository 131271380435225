import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import TimeLayout from './layouts/TimeLayout';
import SpanLayout from './layouts/SpanLayout';
import BilledLayout from './layouts/BilledLayout';
import PaidLayout from './layouts/PaidLayout';
import StatusLayout from './layouts/StatusLayout';
import DxCodeLayout from './layouts/DxCodeLayout';
import FeeCodeLayout from './layouts/FeeCodeLayout';
import CommentLayout from './layouts/CommentLayout';
import ServiceDateLayout from './layouts/ServiceDateLayout';
import ReferralCodeLayout from './layouts/ReferralCodeLayout';
import SequensNumberLayout from './layouts/SequensNumberLayout';
import RejectionCodeLayout from './layouts/RejectionCodeLayout';
import ArchiveButton from './layouts/ArchiveButton';
import SubmissionCodeLayout from './layouts/SubmissionCodeLayout';
import CircularProgress from '../../../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import PatientNameLayout from './layouts/PatientNameLayout';
import PaginatorLeft from './layouts/PaginatorLeft';
import PaginatorRight from './layouts/PaginatorRight';

import { useTable } from '../../hooks/useTable';
import { calculateLast } from '../../../../../utils/calculate';
import { useClaimsListContext } from '../../hooks/useClaimsListContext';
import { setClaimInfo } from '../../../../../claims/actions/claims.action.creators';
import { t } from '../../../../../../service/localization/i18n';

const ClaimsDataTable = () => {
  const dispatch = useDispatch();
  const { state } = useClaimsListContext();
  const { onSelectionChange, fetchData, onSort, onPage, tableStyle, showRejectionColumn, showArchiveColumn } = useTable();
  const sortField = state.sortby?.split('_')[0];
  const sortOrder = state.sortby?.split('_')[1] === 'asc' ? 1 : -1;
  const last = calculateLast({ page: state.page, pageSize: state.pageSize, totalRecords: state.totalRecords });
  const isMobile = window.innerWidth < 768;

  // Get init data
  useEffect(() => {
    fetchData({
      patientGuid: state.PatientGuid,
      page: state.page,
      pageSize: state.pageSize,
      query: state.query,
      sortby: state.sortby
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {state.loading && <CircularProgress />}
      <DataTable
        dataKey="InvoiceGuid"
        value={state.claimList}
        emptyMessage={state.emptyMessage || t('No_records_found')}
        selectionMode="single"
        selection={state.selectedItem}
        onSelectionChange={onSelectionChange}
        lazy
        // stripedRows
        removableSort
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}
        paginator
        pageLinkSize={isMobile ? 2 : 3}
        first={state.first}
        totalRecords={state.totalRecords}
        rows={20}
        paginatorLeft={<PaginatorLeft />}
        paginatorRight={<PaginatorRight />}
        currentPageReportTemplate={isMobile ? '' : `${state.first || 0} to ${last} of ${state.totalRecords}`}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        scrollable
        scrollHeight="flex"
        tableStyle={tableStyle}
        style={{ height: `auto`, maxHeight: 'calc(100vh - 180px)' }}
        onPage={onPage}
        onRowSelect={(e) => dispatch(setClaimInfo(e.data))} // set claim info to highlight it in the invoices data table
      >
        <Column
          sortable
          field="ServiceDate"
          header={t('Date_of_service')}
          style={{ minWidth: '140px' }}
          body={(rowData) => <ServiceDateLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="LastName"
          header={t('Patient.1')}
          style={{ minWidth: '180px' }}
          body={(rowData) => <PatientNameLayout rowData={rowData} />}
        />

        <Column sortable field="Status" header={t('Status')} style={{ minWidth: '150px' }} body={(rowData) => <StatusLayout rowData={rowData} />} />

        <Column
          sortable
          field="FeeCode"
          header={t('Fee_item')}
          style={{ minWidth: '110px' }}
          body={(rowData) => <FeeCodeLayout rowData={rowData} />}
        />

        <Column field="Units" header={t('Units')} style={{ minWidth: '70px' }} />

        <Column
          field="Time"
          header={t('Time')}
          style={{ minWidth: '90px', maxWidth: '120px' }}
          body={(rowData) => <TimeLayout rowData={rowData} />}
        />

        <Column field="Time" header={t('Span')} style={{ minWidth: '90px', maxWidth: '90px' }} body={(rowData) => <SpanLayout rowData={rowData} />} />

        <Column
          sortable
          field="ICD9Code"
          header={t('Dx')}
          style={{ minWidth: '100px', maxWidth: '150px' }}
          body={(rowData) => <DxCodeLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="FeeTotal"
          header={t('Billed')}
          style={{ minWidth: '100px', maxWidth: '150px' }}
          body={(rowData) => <BilledLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="FeePaid"
          header={t('Paid')}
          style={{ minWidth: '100px', maxWidth: '150px' }}
          body={(rowData) => <PaidLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="RefNumber"
          header="Ref."
          style={{ minWidth: '80px', maxWidth: '150px' }}
          body={(rowData) => <ReferralCodeLayout rowData={rowData} />}
        />

        <Column field="SubmissionCode" header="Code" style={{ minWidth: '60px' }} body={(rowData) => <SubmissionCodeLayout rowData={rowData} />} />

        {showRejectionColumn && (
          <Column
            field="ResponseCode"
            header="Rejection"
            style={{ minWidth: '90px' }}
            body={(rowData) => <RejectionCodeLayout rowData={rowData} />}
          />
        )}

        <Column
          field="Comment"
          header="Note"
          style={{ minWidth: '80px', maxWidth: '350px' }}
          body={(rowData) => <CommentLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="RecordNo"
          header="Seq. #"
          style={{ minWidth: '90px' }}
          body={(rowData) => <SequensNumberLayout rowData={rowData} />}
        />

        {/* {showArchiveColumn && <Column field="rowActions" style={{ minWidth: '80px' }} body={(rowData) => <ArchiveButton rowData={rowData} />} />} */}
      </DataTable>
    </>
  );
};

export default ClaimsDataTable;
