import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import CreateRecordCard from './CreateRecordCard';
import GlobalSearch from '../GlobalSearch';
import { Button } from 'primereact/button';

import { elementIDs } from '../../../config/elementIDsConfig';
import { routes } from '../../../../routes/routes';
import { t } from '../../../../service/localization/i18n';

const CreatePatientCard = () => {
  const history = useHistory();

  const newPatient = () => {
    history.push(`${routes.createPatient.path}/new`);
  };

  return (
    <CreateRecordCard title="Create Patient">
      <div className="flex flex-column gap-3">
        <GlobalSearch name="patient" placeholder={t('Enter_name_PHN_phone_or_other_patient_information_here')} />

        <div className="flex align-items-start gap-2">
          <Button
            id={elementIDs.addNewPatient}
            label={t('New_Patient')}
            className="w-max"
            rounded
            text
            // icon="pi pi-plus"
            onClick={newPatient}
          />
        </div>
      </div>
    </CreateRecordCard>
  );
};

export default CreatePatientCard;
