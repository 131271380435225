import { serviceDateFormat } from '../../../../utils/serviceDateFormat';
import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { useSubmission } from './useSubmission';
import { validateOffHours } from '../helpers/validationSchema';
import { getSubmissionCode } from '../helpers/getSubmissionCode';
import { useValidation } from './useValidation';
import { addMissingCodesToRecentList } from '../helpers/updateRecentCodesList';
import moment from 'moment';

export const useServiceDate = () => {
  const { control, getValues, setValue, clearErrors, isNew } = useFormContext();
  const { updateSubmission } = useSubmission();
  const { clearCommentError } = useValidation();

  const onChangeServiceDate = (date) => {
    const name = inputs.serviceDate.name;
    // Update form state
    setValue(name, date, { shouldDirty: true });

    if (date) {
      const field = control.getFieldState(name);
      const feeCodes = getValues(inputs.feeCodes.codeType);
      const startTime = getValues(inputs.startTime.name);
      const feeCodesOnly = feeCodes?.map((i) => i.value);
      const invoiceType = getValues(inputs.payor.name);
      const status = Number(getValues(inputs.status.name));
      const submission = getSubmissionCode({ invoiceType, status, serviceDate: date, isNew });

      localStorage.setItem(localStorageKeys.serviseDate, JSON.stringify(date ? serviceDateFormat(date) : null)); // [KS] CMO-1423 - Persist service date during session

      // Clear error message
      if (field.error) clearErrors(name);

      // Clear error message for commen input
      clearCommentError({ submissionCode: submission, serviceDate: date });

      // CheckOffHours warn message
      feeCodesOnly?.length && startTime && validateOffHours(feeCodesOnly, startTime, date);

      // Add date to the quick pick catalogs if it's not in the list
      const selectedCodes = { [inputs.serviceDate.codeType]: [serviceDateFormat(date)] };
      addMissingCodesToRecentList({ selectedCodes });

      // VER-250 - rebill - change in date defaults submission code
      updateSubmission({ invoiceType, serviceDate: date });
    }
  };

  const onChangeMultipleServiceDate = (dates) => {
    const datesValue = dates || [];
    datesValue.sort((a, b) => moment(a) - moment(b));
    const name = inputs.serviceDate.name;

    // Update form state
    setValue(name, datesValue, { shouldDirty: true });

    if (datesValue?.length) {
      const field = control.getFieldState(name);
      // Clear error message
      if (field.error) clearErrors(name);
      // Add date to the quick pick catalogs if it's not in the list
      const formattedDates = datesValue.map((i) => serviceDateFormat(i));
      const selectedCodes = { [inputs.serviceDate.codeType]: formattedDates };
      addMissingCodesToRecentList({ selectedCodes });
    }
  };

  return { onChangeServiceDate, onChangeMultipleServiceDate };
};
