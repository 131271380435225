import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import ChartCard from './ChartCard';
import { setClaimsDialogVisibility } from '../../actions/cashFlow.axtion.creators';
import { currencyFormat } from '../../../utils/currencyFormat';
import ChartCardTitle from './ChartCardTitle';
import { formatDate } from '../../../utils/formatDate';
import { colors } from '../../../config/stylesConfig';
import moment from 'moment';
import { isChartDataEmpty } from '../../helpers/isChartsDataEmpty';

export default function NumberOfPatients() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cashFlow.data);
  const requestParams = useSelector((state) => state.cashFlow.requestParams);
  const query = requestParams.query;
  const loading = useSelector((state) => state.cashFlow.cashFlowLoading);
  const dateFormat = 'MMM Do, YYYY';

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  const options = useMemo(() => {
    return {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const dataset = tooltipItem.dataset;
              return dataset.tooltipLabels[tooltipItem.dataIndex]; // Use tooltipLabels from dataset
            }
          }
        }
      },
      scales: {
        x: {
          stacked: true, // Stack the X axis
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          stacked: true, // Stack the Y axis
          ticks: {
            color: textColorSecondary,
            stepSize: 1, // Ensure that only whole numbers are shown
            beginAtZero: true, // Start the scale from zero
            callback: function (value) {
              if (Number.isInteger(value)) {
                return value; // Show only integer values
              }
            }
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      },
      onClick: (event, activeElements) => {
        if (activeElements.length > 0) {
          const chart = activeElements[0].element.$context.chart;
          const datasetIndex = activeElements[0].datasetIndex; // Get index of dataset
          const dataIndex = activeElements[0].index; // Get index of the bar
          const clickedDate = chart.data.labels[dataIndex]; // Date label for the clicked bar

          dispatch(
            setClaimsDialogVisibility({
              visible: true,
              periodFrom: moment(clickedDate, dateFormat),
              requestParams: {
                ...requestParams,
                query: {
                  ...requestParams.query,
                  PeriodFrom: moment(clickedDate, dateFormat).format('YYYY-MM-DD'),
                  PeriodTo: moment(clickedDate, dateFormat).format('YYYY-MM-DD'),
                  Period: '7',
                  SubmissionPeriodFrom: '2000-01-01', // Hack
                  SubmissionPeriodTo: moment().format('YYYY-MM-DD'),
                  SubmissionPeriod: '7',
                  RecordType: '10' // Teleplan claims
                }
              }
            })
          );
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textColor, textColorSecondary, surfaceBorder]);

  const chartData = useMemo(() => {
    if (data && data.ListSideBySide) {
      // Step 1: Gather all unique periods across all datasets
      const allPeriodsSet = new Set();
      data.ListSideBySide.forEach((dataset) => {
        dataset.StackData.forEach((item) => {
          allPeriodsSet.add(moment(item.PeriodStart).format(dateFormat));
        });
      });
      const allPeriods = Array.from(allPeriodsSet).sort((a, b) => new Date(a) - new Date(b));

      // Step 2: Extract data for Billed, Paid, In Process, and Declined, aligning with all periods
      const billedData = data.ListSideBySide.find((d) => d.DataName === 'Billed')?.StackData || [];
      const paidData = data.ListSideBySide.find((d) => d.DataName === 'Paid')?.StackData || [];
      const inProcessData = data.ListSideBySide.find((d) => d.DataName === 'In Process')?.StackData || [];
      const declinedData = data.ListSideBySide.find((d) => d.DataName === 'Declined')?.StackData || [];

      // Step 3: Align data with all periods, ensuring every period has a value
      const alignDataWithPeriods = (dataset, valueKey) => {
        return allPeriods.map((period) => {
          const item = dataset.find((i) => moment(i.PeriodStart).format(dateFormat) === period);
          return item ? item[valueKey] : 0; // If period not found, default to 0
        });
      };

      // Helper function to format the patient label (singular or plural)
      const formatClaimsLabel = (count) => (count === 1 ? 'patient' : 'patients');

      const totalPatients = data.TotalPatients;
      const billedValues = alignDataWithPeriods(billedData, 'TotalPatients'); // Using 'TotalPatients' for number of patients
      const paidValues = alignDataWithPeriods(paidData, 'TotalPatients');
      const inProcessValues = alignDataWithPeriods(inProcessData, 'TotalPatients');
      const declinedValues = alignDataWithPeriods(declinedData, 'TotalPatients');

      // Step 4: Create tooltip labels for each dataset, aligning with all periods
      const createTooltipLabels = (dataset, labelPrefix, valueKey) => {
        return allPeriods.map((period) => {
          const item = dataset.find((i) => moment(i.PeriodStart).format(dateFormat) === period);
          return item ? `${labelPrefix}: ${item.TotalPatients} ${formatClaimsLabel(item.TotalPatients)}, ${currencyFormat(item[valueKey])}` : '';
        });
      };

      const billedTooltipLabels = createTooltipLabels(billedData, 'Billed', 'TotalBilled');
      const paidTooltipLabels = createTooltipLabels(paidData, 'Paid', 'TotalPaid');
      const inProcessTooltipLabels = createTooltipLabels(inProcessData, 'In Process', 'TotalBilled');
      const declinedTooltipLabels = createTooltipLabels(declinedData, 'Declined', 'TotalBilled');

      // Step 5: Prepare datasets for the chart
      const datasets = [
        {
          id: 'billed',
          label: `Billed (${totalPatients} ${formatClaimsLabel(totalPatients)})`,
          backgroundColor: colors.billed,
          data: billedValues,
          tooltipLabels: billedTooltipLabels
        },
        {
          id: 'paid',
          label: `Paid (${paidData.reduce((acc, item) => acc + item.TotalPatients, 0)} ${formatClaimsLabel(
            paidData.reduce((acc, item) => acc + item.TotalPatients, 0)
          )})`,
          backgroundColor: colors.success,
          data: paidValues,
          tooltipLabels: paidTooltipLabels,
          stack: 'Stack2'
        },
        {
          id: 'in_process',
          label: `In Process (${inProcessData.reduce((acc, item) => acc + item.TotalPatients, 0)} ${formatClaimsLabel(
            inProcessData.reduce((acc, item) => acc + item.TotalPatients, 0)
          )})`,
          backgroundColor: colors.info,
          data: inProcessValues,
          tooltipLabels: inProcessTooltipLabels,
          stack: 'Stack2'
        },
        {
          id: 'declined',
          label: `Declined (${declinedData.reduce((acc, item) => acc + item.TotalPatients, 0)} ${formatClaimsLabel(
            declinedData.reduce((acc, item) => acc + item.TotalPatients, 0)
          )})`,
          backgroundColor: colors.danger,
          data: declinedValues,
          tooltipLabels: declinedTooltipLabels,
          stack: 'Stack2'
        }
      ];

      return {
        labels: allPeriods, // Use all unique periods as labels
        datasets
      };
    }

    return null;
  }, [data]);

  const title = (
    <ChartCardTitle
      title="Number of Patients with Submitted and Paid Claims"
      subtitle={`Displaying patients from ${formatDate(query.PeriodFrom)}, to ${formatDate(query.PeriodTo)}`}
    />
  );

  return (
    <ChartCard title={title} loading={loading} isChartDataEmpty={isChartDataEmpty(chartData)}>
      <Chart type="bar" data={chartData} options={options} />
    </ChartCard>
  );
}
