import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import ChartCard from './ChartCard';
import { Chart } from 'primereact/chart';

import { statuses } from '../../../config/statuses';
import { colors, statusColors } from '../../../config/stylesConfig';
import { isChartDataEmpty } from '../../helpers/isChartsDataEmpty';
import { currencyFormat } from '../../../utils/currencyFormat';
import moment from 'moment';

export default function ChartByStatus() {
  const data = useSelector((state) => state.cashFlow.data);
  const loading = useSelector((state) => state.cashFlow.cashFlowLoading);
  const dateFormat = 'MMM Do, YYYY';

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  const options = useMemo(() => {
    return {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const dataset = tooltipItem.dataset;
              return dataset.tooltipLabels[tooltipItem.dataIndex]; // Use tooltipLabels from dataset
            }
          }
        },
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textColor, textColorSecondary, surfaceBorder]);

  const chartData = useMemo(() => {
    if (!data || !data?.ListByStatus) return null;

    // Generate labels from all datasets combined and sort them by date.
    const allLabels = Object.keys(data?.ListByStatus)
      .flatMap((key) => data?.ListByStatus[key].map((item) => moment(item.PeriodStart).format(dateFormat)))
      // Ensure labels are unique
      .filter((value, index, self) => self.indexOf(value) === index)
      // Sort the labels by date using moment
      .sort((a, b) => moment(a, dateFormat).toDate() - moment(b, dateFormat).toDate());

    const datasets = Object.keys(data?.ListByStatus).map((key) => {
      const totalBilled = data?.ListByStatus?.[key]?.reduce((acc, item) => acc + item.TotalBilled, 0);
      const label = `${statuses[key]} (${currencyFormat(totalBilled)})`;

      // Map data for each label with corresponding billed value and claim count
      const dataset = data?.ListByStatus?.[key].map((item) => ({
        date: moment(item.PeriodStart).format(dateFormat),
        value: item.TotalBilled,
        claimsCount: item.TotalCount // Store claims count for each bar
      }));

      const filteredData = allLabels.map((label) => {
        const match = dataset.find((d) => d.date === label);
        return match ? match.value : 0; // Show 0 if there's no matching date
      });

      const claimsData = allLabels.map((label) => {
        const match = dataset.find((d) => d.date === label);
        return match ? match.claimsCount : 0; // Show 0 if there's no matching claim count
      });

      // Create tooltipLabel for each bar
      const tooltipLabels = allLabels.map((label, index) => {
        return `${statuses[key]} ${currencyFormat(filteredData[index])}, Claim(s): ${claimsData[index]}`;
      });

      return {
        type: 'bar',
        label, // Legend will show the status name
        backgroundColor: statusColors[key] || colors.gray, // Use the statusColors mapping
        data: filteredData, // Billed values for the bars
        claimsCount: claimsData, // Claims count for the bars
        tooltipLabels // Tooltip labels for each bar
      };
    });

    return {
      labels: allLabels,
      datasets
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ChartCard title="Teleplan Claims by Status (CAD)" loading={loading} isChartDataEmpty={isChartDataEmpty(chartData)}>
      <Chart type="bar" data={chartData} options={options} />
    </ChartCard>
  );
}
