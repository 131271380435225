import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { icd9CodeMaxEntry } from '../../../../config/defaultValuesConfig';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { isTimeValid, parseMilitaryTime } from '../../../../utils/formatTime';
import { calculateUnits } from '../helpers/calculateUnits';
import { feeCodesThatNotRequiresMandatoryValues } from '../helpers/codesCheckList';
import { filter, flatMap, get, groupBy, omit, some, unionBy } from 'lodash';
import { feeCodeNeedsOnlyStartTime, feeCodeNeedsTime, getPsychiatryCodesWithWrongDuration } from '../helpers/claimFormValidation';
import { calculateSurgicalAssist } from '../helpers/calculateSurgicalAssist';
import { inputs } from '../helpers/inputs';
import { v4 as uuidv4 } from 'uuid';
import { t } from '../../../../../service/localization/i18n';
import { commentInputRequirements } from '../helpers/validationSchema';
import moment from 'moment';

export const useUpdateRecords = () => {
  const dispatch = useDispatch();
  const { localState, setLocalState, getValues } = useFormContext();
  const records = localState.records;
  const isInvalidUnits = localState.records?.some(
    (i) => i.max_unit && Number(i.max_unit) !== -1 && (Number(i.units) > Number(i.max_unit) || Number(i.units) === 0)
  );

  const handleDxChange = (e, options) => {
    if (e.value?.length > icd9CodeMaxEntry) {
      return dispatch(setToastMessage({ type: 'warn', message: `${t('Maximum_number_of_entries_is_X')} ${icd9CodeMaxEntry}` }));
    }

    const { rowData, field } = options;

    const _codes = e.value?.map((i) => i.value)?.join();
    const _codesDescription = e.value?.map((i) => i.text || i.label)?.join('|');

    let _records = [...localState.records];
    let newData = options.rowData;

    newData.icd9 = _codes;
    newData.icd9_text = _codesDescription;
    let recordsRequiredInputs = localState.recordsRequiredInputs;

    if (e.value?.length) {
      recordsRequiredInputs = updatedRecordsRequiredInputs(rowData.id, field);
    } else {
      const isFeeRequiresMandatoryValues = isFeeCodeRequiresMandatoryValues(rowData.fee_code);
      recordsRequiredInputs = updatedRecordsRequiredInputs(rowData.id, field, isFeeRequiresMandatoryValues);
    }

    _records[options.rowIndex] = newData;
    setLocalState((prevState) => ({
      ...prevState,
      records: _records,
      isStep2Dirty: false,
      recordsRequiredInputs: recordsRequiredInputs
    }));
  };

  const update13003Record = ({ recordsList, isDelete = false }) => {
    const rowWith13003code = recordsList?.find((i) => i.fee_code === '13003');

    if (rowWith13003code) {
      let _records = [...recordsList];
      const feeCodes = getValues(inputs.feeCodes.codeType);
      const rowIndex = recordsList.findIndex((i) => i.fee_code === rowWith13003code.fee_code);
      const amountFor13003 = calculateSurgicalAssist({ feeCodes, records: recordsList });
      const billed = (amountFor13003 * rowWith13003code.units * rowWith13003code.percent) / 100;
      rowWith13003code.fee = amountFor13003;
      rowWith13003code.billed = billed;

      if (isDelete) {
        const code13003 = feeCodes?.find((i) => i.value === '13003');
        const baseCodesList = code13003?.base_codes?.split(',');
        const baseCodes = recordsList?.filter((i) => baseCodesList.some((x) => x === i.fee_code));
        const baseCodesOnly = baseCodes?.map((i) => i.fee_code);
        rowWith13003code.warning = `Fee is calculated as 25.00% of the fee codes ${baseCodesOnly?.join()}`;
      }

      _records[rowIndex] = rowWith13003code;
      return _records;
    } else {
      return recordsList;
    }
  };

  const onFeeEditorChange = (value, options) => {
    let _records = [...records];
    let { rowData, rowIndex, field } = options;

    const billed = (value * rowData.units * rowData.percent) / 100;
    rowData.billed = Number(billed.toFixed(2));
    rowData[field] = value;

    _records[rowIndex] = rowData;
    let recordsRequiredInputs = localState.recordsRequiredInputs;

    if (value <= 0 || !value) {
      recordsRequiredInputs = updatedRecordsRequiredInputs(rowData.id, 'fee', true);
    } else {
      recordsRequiredInputs = updatedRecordsRequiredInputs(rowData.id, 'fee', false);
    }

    update13003Record({ recordsList: _records });
    setLocalState((prevState) => ({ ...prevState, records: _records, isStep2Dirty: false, recordsRequiredInputs: recordsRequiredInputs }));
  };

  const onUnitsEditorChange = (value, options) => {
    let _records = [...records];
    let { rowData, rowIndex, field } = options;

    const billed = (rowData.fee * value * rowData.percent) / 100;
    rowData.billed = Number(billed.toFixed(2));
    rowData[field] = value;

    _records[rowIndex] = rowData;
    update13003Record({ recordsList: _records });
    setLocalState((prevState) => ({ ...prevState, records: _records, isStep2Dirty: false }));
    noteRequirementValidation({ rowData });
  };

  const onStartTimeEditorChange = (value, options) => {
    let _records = [...records];
    let { rowData, rowIndex, field } = options;
    const feeCode = { min_per_unit: rowData.per_unit, portion: rowData.portion, max_unit: rowData.max_unit };
    const endTime = parseMilitaryTime(rowData.service_finish);
    const isStartValid = isTimeValid(value);
    const isEndValid = isTimeValid(endTime);
    let recordsRequiredInputs = localState.recordsRequiredInputs;

    if (isStartValid && isEndValid) {
      const units = calculateUnits(feeCode, value, endTime);
      const billed = (rowData.fee * units * rowData.percent) / 100;
      rowData.units = units;
      rowData.billed = Number(billed.toFixed(2));
      if (units < 1) {
        recordsRequiredInputs = updatedRecordsRequiredInputs(rowData.id, 'units', true);
      } else {
        recordsRequiredInputs = updatedRecordsRequiredInputs(rowData.id, 'units');
      }
    }

    rowData[field] = value;
    _records[rowIndex] = rowData;
    update13003Record({ recordsList: _records });
    setLocalState((prevState) => ({
      ...prevState,
      records: _records,
      isStep2Dirty: false,
      recordsRequiredInputs
    }));
  };

  const onEndTimeEditorChange = (value, options) => {
    let _records = [...records];
    let { rowData, rowIndex, field } = options;
    const feeCode = { min_per_unit: rowData.per_unit, portion: rowData.portion, max_unit: rowData.max_unit };
    const startTimeTime = parseMilitaryTime(rowData.service_start);
    const isStartValid = isTimeValid(startTimeTime);
    const isEndValid = isTimeValid(value);
    let recordsRequiredInputs = localState.recordsRequiredInputs;

    if (isStartValid && isEndValid) {
      const units = calculateUnits(feeCode, startTimeTime, value);
      const billed = (rowData.fee * units * rowData.percent) / 100;
      rowData.units = units;
      rowData.billed = Number(billed.toFixed(2));
      if (units < 1) {
        recordsRequiredInputs = updatedRecordsRequiredInputs(rowData.id, 'units', true);
      } else {
        recordsRequiredInputs = updatedRecordsRequiredInputs(rowData.id, 'units');
      }
    }

    rowData[field] = value;
    _records[rowIndex] = rowData;
    update13003Record({ recordsList: _records });
    setLocalState((prevState) => ({
      ...prevState,
      records: _records,
      isStep2Dirty: false,
      recordsRequiredInputs
    }));
  };

  const handleTimeBlur = (time, options) => {
    const { rowData, field } = options;
    const feeCode = rowData.fee_code;
    const isValidTime = isTimeValid(time);

    // WIN-180 - Time validation does not work properly on second step of MSP screen
    if (time && !isValidTime) {
      options.editorCallback('');
      return;
    }

    // Handle special case for '24:00'
    if (time === '24:00') {
      options.editorCallback('00:00');
      return;
    }

    // Check if the time exceeds 23:59
    if (parseInt(time?.replace(':', ''), 10) > 2359) {
      options.editorCallback('');
      return;
    }

    const isStartTimeInput = field === 'service_start';
    const isEndTimeInput = field === 'service_finish';
    const isStartTimeInputRequired = isStartTimeInput && (feeCodeNeedsOnlyStartTime(feeCode) || feeCodeNeedsTime(feeCode));
    const isEndTimeInputRequired = isEndTimeInput && feeCodeNeedsTime(feeCode);

    const isDuplicate = isDuplicateRecord(rowData);

    // Update state based on validation results
    const updatedRecordsRequiredInputs = {
      ...localState.recordsRequiredInputs,
      [rowData.id]: {
        ...localState.recordsRequiredInputs[rowData.id],
        [field]: { required: false, errorMessage: '' }
      }
    };

    if (isDuplicate) {
      if (!isValidTime) {
        updatedRecordsRequiredInputs[rowData.id][field] = { required: true, errorMessage: t('Mandatory_field.1') };
      }
    } else if (!isValidTime && (isStartTimeInputRequired || isEndTimeInputRequired)) {
      updatedRecordsRequiredInputs[rowData.id][field] = { required: true, errorMessage: t('Mandatory_field.1') };
    }

    setLocalState((prevState) => ({
      ...prevState,
      recordsRequiredInputs: updatedRecordsRequiredInputs
    }));

    if (isValidTime) {
      // Validate note requirement
      noteRequirementValidation({
        rowData: {
          ...rowData,
          service_start: isStartTimeInput ? time : rowData.service_start,
          service_finish: isStartTimeInput ? time : rowData.service_finish
        }
      });
    }

    // Callback with the validated time
    options.editorCallback(time);

    // Validate units requirement
    unitsRequirementValidation();
  };

  const onUnitsEditorBlur = (value, options) => {
    const recordsRequiredInputs = updatedRecordsRequiredInputs(options.rowData.id, 'units');
    setLocalState((prevState) => ({ ...prevState, recordsRequiredInputs }));
    if (value > 0) return;
    onUnitsEditorChange('1', options);
  };

  const onUnitsEditorFocus = (value, options) => {
    // VER-472 - Teleplan->2nd screen->change default value in units after user clicks on Required message
    if (Number(value) === 0) onUnitsEditorChange('1', options);
  };

  const onPercentEditorChange = (value, options) => {
    let _records = [...records];
    let { rowData, rowIndex, field } = options;

    const billed = (rowData.fee * rowData.units * value) / 100;
    rowData.billed = Number(billed.toFixed(2));
    rowData[field] = value;

    _records[rowIndex] = rowData;
    update13003Record({ recordsList: _records });
    setLocalState((prevState) => ({ ...prevState, records: _records, isStep2Dirty: false }));
  };

  const onPercentEditorBlur = (value, options) => {
    if (value) return;
    return onPercentEditorChange('100', options);
  };

  const handleCommentChange = (value, rowData) => {
    let newData = rowData;
    newData.note = value;
    setLocalState((prevState) => ({ ...prevState, isStep2Dirty: true }));
  };

  const onBlurComment = (value, rowData) => {
    noteRequirementValidation({ rowData, note: value });
  };

  const handleDeleteRecord = (rowData) => {
    setLocalState((prevState) => {
      const resetedRowDuplicateRecords = resetRowDuplicate(localState.records);
      const updatedRecords = resetedRowDuplicateRecords.filter((i) => i.id !== rowData.id);
      const updatedRecordsRequiredInputs = removeRowRequirementsForDuplicatedRecords(rowData.id);
      update13003Record({ recordsList: updatedRecords, isDelete: true });
      const sortedRecordsByServiceDate = updatedRecords.sort((a, b) => new Date(a.service_date) - new Date(b.service_date)); // VER-671 Multi-date->deleting one code results in broken layout on 2nd step

      return {
        ...prevState,
        records: sortedRecordsByServiceDate,
        isStep2Dirty: prevState.records?.length > 1,
        recordsRequiredInputs: updatedRecordsRequiredInputs
      };
    });
  };

  const isFeeCodeRequiresMandatoryValues = (feeCode) => {
    return !feeCodesThatNotRequiresMandatoryValues?.includes(feeCode);
  };

  // VER-391 - Teleplan->incorrect validation of 00638
  // Set required notes
  const isNoteRequired = ({ feeCode = '', startTime, endTime, units }) => {
    const speciality = getValues(inputs.speciality.name);
    const submissionCode = getValues(inputs.submission.name);
    const isCommentRequired = commentInputRequirements({
      feeCodes: [{ value: feeCode }],
      startTime,
      endTime,
      step: localState.step,
      units,
      speciality,
      submissionCode
    });

    return isCommentRequired;
  };

  const isDuplicateRecord = (rowData) => {
    return some(localState.records, (i) => {
      const isSameDates = moment(rowData.service_date).isSame(moment(i.service_date), 'day'); // Check if dates are same because of adding multiple dates functionality
      return i.fee_code === rowData.fee_code && i.id !== rowData.id && isSameDates;
    });
  };

  // VER-391 - Teleplan->incorrect validation of 00638
  // Set required notes
  const noteRequirementValidation = ({ rowData, note }) => {
    const feeCode = rowData.fee_code;
    const units = rowData.units;
    const startTime = parseMilitaryTime(rowData.service_start);
    const endTime = parseMilitaryTime(rowData.service_finish);
    const currentNote = note || rowData.note;
    const isRequired = isNoteRequired({ feeCode, startTime, endTime, units });
    const isRequiredNote = isRequired && !currentNote;
    const isDuplicate = isDuplicateRecord(rowData);

    if (isDuplicate) {
      if (currentNote) {
        setLocalState((prevState) => {
          return {
            ...prevState,
            recordsRequiredInputs: {
              ...prevState.recordsRequiredInputs,
              [rowData.id]: {
                ...localState.recordsRequiredInputs[rowData.id],
                note: {
                  required: false,
                  errorMessage: ''
                }
              }
            }
          };
        });
      } else {
        const isFeeRequiresMandatoryValues = isFeeCodeRequiresMandatoryValues(rowData.fee_code);
        setLocalState((prevState) => {
          return {
            ...prevState,
            recordsRequiredInputs: {
              ...prevState.recordsRequiredInputs,
              [rowData.id]: {
                ...localState.recordsRequiredInputs[rowData.id],
                note: {
                  required: isFeeRequiresMandatoryValues,
                  errorMessage: isFeeRequiresMandatoryValues ? t('Mandatory_field.1') : ''
                }
              }
            }
          };
        });
      }
    } else {
      // Set original row note input as required
      setLocalState((prevState) => {
        return {
          ...prevState,
          recordsRequiredInputs: {
            ...prevState.recordsRequiredInputs,
            [rowData.id]: {
              ...localState.recordsRequiredInputs[rowData.id],
              note: {
                required: isRequiredNote,
                errorMessage: isRequiredNote ? t('Mandatory_field.1') : ''
              }
            }
          }
        };
      });
    }
  };

  const unitsRequirementValidation = () => {
    // Map over each record in the records array
    const updatedRecords = records
      .map((record) => {
        // Check if units are missing or zero
        if (!record.units || record.units === 0) {
          // If missing or zero, update the required inputs for this record
          return updatedRecordsRequiredInputs(record.id, 'units', true);
        }
        // Return null for records where units are not missing or zero
        return null;
      })
      // Filter out null values from the resulting array
      .filter((updatedRecord) => updatedRecord !== null);

    // Merge all the updated records into a single object
    setLocalState((prevState) => ({
      ...prevState,
      recordsRequiredInputs: {
        ...prevState.recordsRequiredInputs,
        ...Object.assign({}, ...updatedRecords)
      }
    }));
  };

  const handleDuplicateRecord = (rowData, rowIndex) => {
    const feeCode = rowData.fee_code;
    const startTime = parseMilitaryTime(rowData.service_start);
    const endTime = parseMilitaryTime(rowData.service_finish);
    const isStartValid = isTimeValid(startTime);
    const isEndValid = isTimeValid(endTime);
    const isFeeRequiresMandatoryValues = isFeeCodeRequiresMandatoryValues(feeCode);
    const isStartRequired = isFeeRequiresMandatoryValues;
    const isEndRequired = isFeeRequiresMandatoryValues;
    const isNoteRequired = isFeeRequiresMandatoryValues;

    const cloneItem = {
      ...rowData,
      id: uuidv4(),
      service_start: '',
      service_finish: '',
      note: '',
      isDuplicate: true
    };

    setLocalState((prevState) => {
      // Insert the duplicated item right after the original item
      prevState.records.splice(rowIndex + 1, 0, cloneItem);

      return {
        ...prevState,
        isStep2Dirty: prevState.records?.length > 1,

        // Set required inputs
        recordsRequiredInputs: {
          ...prevState.recordsRequiredInputs,

          // Set original row time and note inputs as required
          [rowData.id]: {
            service_start: {
              required: !isStartValid && isStartRequired,
              errorMessage: isStartRequired ? t('Mandatory_field.1') : ''
            },
            service_finish: {
              required: !isEndValid && isEndRequired,
              errorMessage: isEndRequired ? t('Mandatory_field.1') : ''
            },
            note: {
              required: isNoteRequired && !rowData.note,
              errorMessage: isNoteRequired ? t('Mandatory_field.1') : ''
            },
            isDuplicate: false
          },

          // Set duplicated row time and note inputs as required
          [cloneItem.id]: {
            service_start: {
              required: isStartRequired,
              errorMessage: isStartRequired ? t('Mandatory_field.1') : ''
            },
            service_finish: {
              required: isEndRequired,
              errorMessage: isEndRequired ? t('Mandatory_field.1') : ''
            },
            note: {
              required: isNoteRequired,
              errorMessage: isNoteRequired ? t('Mandatory_field.1') : ''
            },
            isDuplicate: true
          }
        }
      };
    });

    update13003Record({ recordsList: records });
  };

  const removeRowRequirementsForDuplicatedRecords = (currentRecordRowId) => {
    const duplicatedItems = getDuplicatedRecords();

    // Copy the recordsRequiredInputs object, excluding the currentRecordRowId
    let recordsRequiredInputs = omit(localState.recordsRequiredInputs, currentRecordRowId);

    // If there are exactly two duplicated items, remove their entries from recordsRequiredInputs
    if (duplicatedItems.length === 2) {
      // Get the row IDs of the duplicated items
      const duplicatedRowIds = duplicatedItems.map((i) => i.id);

      // Remove the entries corresponding to the duplicated row IDs
      recordsRequiredInputs = omit(recordsRequiredInputs, duplicatedRowIds);
    }

    // Return the modified recordsRequiredInputs object
    return recordsRequiredInputs;
  };

  const resetRowDuplicate = (records) => {
    let duplicatedItems = getDuplicatedRecords();

    // If there are exactly two duplicated items, remove their entries from recordsRequiredInputs
    if (duplicatedItems.length === 2) {
      // Get the row IDs of the duplicated items
      duplicatedItems = duplicatedItems.map((i) => ({ ...i, isDuplicate: false }));
    }

    return unionBy(duplicatedItems, records, 'id');
  };

  const getDuplicatedRecords = () => {
    const recordsList = localState.records;
    const groupedByCondition = groupBy(recordsList, (i) => i.fee_code);
    const duplicatedItemsGroups = filter(groupedByCondition, (items) => items.length > 1);
    const duplicatedItems = flatMap(duplicatedItemsGroups, (items) => items);
    return duplicatedItems;
  };

  const onRowEditComplete = (e) => {
    let _records = [...records];
    let { newData, index } = e;

    newData.icd9 = newData.icd9 || localState.records[index].icd9;
    newData.icd9_text = newData.icd9_text || localState.records[index].icd9_text;

    const billed = (newData.fee * newData.units * newData.percent) / 100;
    newData.billed = Number(billed.toFixed(2));

    // CMO-1894 - Create/Edit teleplan - disable percent input if units value > 1
    if (Number(newData.units) > 1) newData.percent = 100;

    _records[index] = newData;

    setLocalState((prevState) => ({ ...prevState, records: _records, isStep2Dirty: true }));
  };

  // Check if a certain input field is required for a specific row
  const isRequiredInput = (rowId, fieldName) => {
    // Use lodash's `some` function to iterate through the keys of `recordsRequiredInputs`
    // and check if any key matches the provided `rowId`
    return some(localState.recordsRequiredInputs, (value, key) => {
      // If the current key matches the provided `rowId`
      if (key === rowId) {
        // Use lodash's `get` function to safely retrieve the `required` value of the specified field (`fieldName`)
        const fieldRequired = get(value, [fieldName, 'required']);
        // Return `true` if the field exists and has a `required` value of `true`, otherwise return `false`
        return fieldRequired === true;
      }
      return false;
    });
  };

  // Check if any input field is required across all rows
  const isAnyInputRequired = () => {
    // Use lodash's `some` function to iterate through each row's inputs and
    // check if any of the fields have a `required` value of `true`
    return some(localState.recordsRequiredInputs, (rowInputs) => some(rowInputs, (field) => field.required));
  };

  const updatedRecordsRequiredInputs = (rowId, field, required = false) => {
    const errorMessage = required ? t('Mandatory_field.1') : '';
    return {
      ...localState.recordsRequiredInputs,
      [rowId]: {
        ...localState.recordsRequiredInputs[rowId],
        [field]: { required, errorMessage }
      }
    };
  };

  const durationValidationForPsychiatryCodes = ({ isCreateClicked } = {}) => {
    let isInvalid = false;
    localState.records.some((record) => {
      const feeCodes = getValues(inputs.feeCodes.codeType)?.find((code) => code.value === record.fee_code);
      const startTime = record.service_start ? parseMilitaryTime(record.service_start) : null;
      const endTime = record.service_finish ? parseMilitaryTime(record.service_finish) : null;

      if ((!startTime && !endTime) || !feeCodes) return false;

      // Find a matching fee code based on the provided fee codes
      const psychiatryCodesWithWrongDuration = getPsychiatryCodesWithWrongDuration({ feeCodes: [feeCodes], startTime, endTime });
      const codeMatch = psychiatryCodesWithWrongDuration?.find((i) => [feeCodes].some((code) => code.value === i.value));

      if (codeMatch) {
        const isValid = psychiatryCodesWithWrongDuration?.length;
        isValid &&
          setLocalState((prevState) => ({
            ...prevState,
            durationForPsychiatryCodesDialog: {
              showDialog: true,
              options: { record, isCreateClicked }
            }
          }));
        isInvalid = isValid;
        return isValid;
      } else {
        return false;
      }
    });

    return isInvalid;
  };

  return {
    isInvalidUnits,
    handleDxChange,
    onRowEditComplete,
    handleCommentChange,
    onBlurComment,
    handleDeleteRecord,
    onUnitsEditorChange,
    onUnitsEditorBlur,
    onUnitsEditorFocus,
    onPercentEditorChange,
    onPercentEditorBlur,
    onStartTimeEditorChange,
    onEndTimeEditorChange,
    handleTimeBlur,
    handleDuplicateRecord,
    isRequiredInput,
    isAnyInputRequired,
    onFeeEditorChange,
    noteRequirementValidation,
    unitsRequirementValidation,
    durationValidationForPsychiatryCodes
  };
};
