import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import CreateRecordCard from './CreateRecordCard';
import GlobalSearch from '../GlobalSearch';
import { Button } from 'primereact/button';

import { elementIDs } from '../../../config/elementIDsConfig';
import { routes } from '../../../../routes/routes';
import { t } from '../../../../service/localization/i18n';

const CreateWSBCCard = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user.details);

  const newWSBCR = () => {
    history.push(`${routes.newWSBCReport.path}`);
  };

  if (!user.canBillWCB) return null;

  return (
    <CreateRecordCard title="Create WSBC report">
      <div className="flex flex-column gap-3">
        <GlobalSearch name="wsbcr" placeholder={t('Enter_office_number_or_sequence_number_of_the_claim_here')} />

        <div className="flex align-items-start gap-2">
          <Button
            id={elementIDs.createWSBCReportButton}
            className="w-max"
            rounded
            text
            label={t('WSBC_Report')}
            // icon="pi pi-plus"
            onClick={newWSBCR}
          />
        </div>
      </div>
    </CreateRecordCard>
  );
};

export default CreateWSBCCard;
