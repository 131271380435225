import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import CommonCard from '../../../../common/components/CommonCard';
import EligibilityMessage from '../../commonElements/EligibilityMessage';
import MSPEligibilityView from '../../../../dashboard/MSPEligibilityView';
import { editPatient, setEligibility, setPatientInfo } from '../../../actions/patients.action.creators';
import { formatDate } from '../../../../utils/formatDate';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { isEmpty } from 'lodash';
import { t } from '../../../../../service/localization/i18n';
import classes from './EligibilityCard.module.scss';
import cx from 'classnames';

const EligibilityCard = ({ patient_details, isFetchingPHNValidation, isFetchingPatientEligibility, showSkeleton }) => {
  const dispatch = useDispatch();
  const [showMSPEligibilityDialog, setShowMSPEligibilityDialog] = useState(false);
  const seeMSPEligibilityReportButtonLable = t('See_MSP_Eligibility_Report');
  const showEligibility = isEmpty(patient_details?.Eligibility?.Parsed);

  //set eligibility initial state
  useEffect(() => {
    if (patient_details?.Eligibility?.Parsed?.ERROR) dispatch(setEligibility({}));
    if (!isEmpty(patient_details?.Eligibility?.Parsed) && !patient_details?.Eligibility?.Parsed?.ERROR) {
      dispatch(setEligibility(patient_details?.Eligibility?.Parsed));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_details?.Eligibility]);

  const checkMSPCallback = (submitedData, responceData) => {
    dispatch(setToastMessage({ type: 'success', message: t('The_report_is_received') }));
    setShowMSPEligibilityDialog(false);

    if (submitedData?.PHN !== patient_details?.PHN || formatDate(submitedData?.DOB) !== formatDate(patient_details?.BirthDay)) {
      dispatch(
        editPatient({
          ...patient_details,
          PHN: submitedData?.PHN,
          PHNProvince: submitedData?.PHNProvince,
          BirthDay: submitedData?.DOB
        })
      );
    } else {
      //update Eligibility for patient info
      dispatch(
        setPatientInfo({
          ...patient_details,
          Eligibility: responceData
        })
      );
    }
  };

  const footerContent = () => {
    return (
      <Button
        className={cx('p-button-text', classes.footerActionButton)}
        //  disabled={patient_details?.PHNProvince !== "BC"}
        label={showEligibility ? seeMSPEligibilityReportButtonLable : t('Update_MSP_Eligibility')}
        onClick={() => setShowMSPEligibilityDialog(true)}
      />
    );
  };

  return (
    <div className="col-12 p-0">
      <CommonCard title={t('MSP_Eligibility')} showTitleIcon={true} iconTooltip={t('Enter_PHN_and_DOB')} footerContent={footerContent}>
        <>
          <div className="flex align-items-start">
            {showSkeleton ? (
              <div className="flex pr-2 w-full">
                <Skeleton className="mr-3" shape="circle" size="3rem" />
                <div className="w-full">
                  <Skeleton className="mb-2" width="100%" height="2rem" />
                  <Skeleton className="mb-2" width="100%" height="4rem" />
                  <Skeleton width="100%" height="2rem" />
                </div>
              </div>
            ) : (
              <div>
                {showEligibility && <div className="my-3">{String.format(t('No_report_to_display'), seeMSPEligibilityReportButtonLable)}</div>}

                {!showEligibility && (
                  <div className="field mt-2">
                    <EligibilityMessage
                      eligibility={patient_details?.Eligibility?.Parsed}
                      showMessageColor={false}
                      showIcon={true}
                      iconClassName={classes.icon}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      </CommonCard>

      <Dialog
        visible={showMSPEligibilityDialog}
        header={t('MSP_Eligibility')}
        breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
        style={{ width: '30vw' }}
        onHide={() => setShowMSPEligibilityDialog(false)}
      >
        <MSPEligibilityView
          onHide={() => setShowMSPEligibilityDialog(false)}
          patientDetails={patient_details}
          onSubmitCallback={checkMSPCallback}
          isFetchingPHNValidation={isFetchingPHNValidation}
          isFetchingPatientEligibility={isFetchingPatientEligibility}
        />
      </Dialog>
    </div>
  );
};

export default EligibilityCard;
