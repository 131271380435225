import axios from 'axios';
import { getApiBase, setAuthHeader } from '../config';
import { store } from '../store';
import { invoicesCatalogsDefaultPageSize } from '../modules/config/defaultValuesConfig';
import { setToastMessage } from '../modules/core/actions/core.action.creators';
import { patientPickerPageSize } from '../modules/config/defaultValuesConfig';
import patientsForAutocomplete from '../mocks/db/patients/patientsForAutocomplete.json';
import resentFeeCodes from '../mocks/db/claims/codesForCatalogs/fee/resentFeeCodes.json';
import inOfficeVisitsCodes from '../mocks/db/claims/codesForCatalogs/fee/inOfficeVisitsCodes.json';
import resentDxCodes from '../mocks/db/claims/codesForCatalogs/dx/resentDxCodes.json';
import bloodFormingOrgansCodes from '../mocks/db/claims/codesForCatalogs/dx/bloodFormingOrgansCodes.json';
import complicationsOfPregnancyCategoryCodes from '../mocks/db/claims/codesForCatalogs/dx/complicationsOfPregnancyCategoryCodes.json';
import resentAOICodes from '../mocks/db/claims/codesForCatalogs/aoi/resentAOICodes.json';
import trunkCategoryCodes from '../mocks/db/claims/codesForCatalogs/aoi/trunkCategoryCodes.json';
import neckCodes from '../mocks/db/claims/codesForCatalogs/aoi/neckCodes.json';
import resentNOICodes from '../mocks/db/claims/codesForCatalogs/noi/resentNOICodes.json';
import psychologicalCategoryCodes from '../mocks/db/claims/codesForCatalogs/noi/psychologicalCategoryCodes.json';
import traumaCodes from '../mocks/db/claims/codesForCatalogs/noi/traumaCodes.json';
import resentReferralCodes from '../mocks/db/claims/codesForCatalogs/referral/resentReferralCodes.json';
import { getExactMatchesFirst } from '../modules/utils/getExactMatchesFirst';
import prefsActions from '../modules/preferences/actions/preferences.action.types';
import patientActions from '../modules/patients/actions/patients.action.types';
import { formatWSBCClaimReport } from '../modules/claims/helpers/formatWSBCClaimReport';
import { formatPatientDetails } from '../modules/patients/helpers/formatPatientDetails';
import { getPrefersForTests } from '../modules/preferences/actions/responseActions';
import { getMostRecentCodesForTest, getPatientClaimDataForTests } from '../modules/claims/actions/responseActions';
import { getPatientInfoForTests } from '../modules/patients/actions/responseActions';
import { recentServiceDates } from '../modules/claims/helpers/recentServiceDates';

const extractPhoneTypes = (x) => {
  var obj = /([chbo])/.exec(x.Phone);
  if (obj && obj[0]) {
    x.PhoneType = x.Phone.substr(obj.index, 3).replace('(', '').replace(')', '');
  }
  obj = /([chbo])/.exec(x.Phone2);
  if (obj && obj[0]) {
    x.PhoneType2 = x.Phone2.substr(obj.index, 3).replace('(', '').replace(')', '');
  }
  obj = /([chbo])/.exec(x.Phone3);
  if (obj && obj[0]) {
    x.PhoneType3 = x.Phone3.substr(obj.index, 3).replace('(', '').replace(')', '');
  }
  obj = /([pbo])/.exec(x.Email);
  if (obj && obj[0]) {
    x.EmailType = x.Email.substr(obj.index, 3).replace('(', '').replace(')', '');
  }
  return x;
};

const formatList = (results) => {
  const formatted = results.data.patientList.map((x) => {
    return extractPhoneTypes(x);
  });

  return {
    data: {
      patientList: formatted,
      page: results.data.page,
      pageSize: results.data.pageSize,
      totalRecords: results.data.totalRecords,
      totalPages: results.data.totalPages,
      query: results.data.query
    }
  };
};

const getPatientsResponseAction = (patientList) => {
  const updatePatientList = patientList?.map((i) => {
    return {
      ...i,
      FullAddress: `${i.AddressLine} ${i.City} ${i.Province} ${i.Zip}`
    };
  });

  return { patientList: updatePatientList };
};

const getCodesForCatalogResponceAction = (type) => {
  const responceData = {
    // Recent codes
    feecodes_recent: resentFeeCodes,
    icd9_recent: resentDxCodes,
    aoi_recent: resentAOICodes,
    noi_recent: resentNOICodes,
    referral_recent: resentReferralCodes,

    // Codes for categories
    fee710: inOfficeVisitsCodes,
    'icd9_280-289.9': bloodFormingOrgansCodes,
    aoi_neck: neckCodes,
    noi_tmd: traumaCodes,

    // Codes for categories with search value
    'icd9_630-676.9': complicationsOfPregnancyCategoryCodes,
    aoi_trnk: trunkCategoryCodes,
    noi_psd: psychologicalCategoryCodes
  };

  return Promise.resolve(responceData[type] || {});
};

export const getPatientListAC = (req4ac) => {
  return new Promise((resolve, reject) => {
    const dataPage = req4ac.page ? req4ac.page : 1;
    const currentState = store.getState();
    setAuthHeader(currentState);
    const DCNGuid = currentState && currentState.user && currentState.user.details ? currentState.user.details.DCNGuid : req4ac.dcnguid;

    const url =
      `${getApiBase()}/patients/` + DCNGuid + '/' + (req4ac.page ? req4ac.page : '1') + '/10/' + (req4ac.filter ? '?q=' + req4ac.filter : '');
    axios
      .get(url)
      .then((results) => {
        if (req4ac.prefixItems && req4ac.prefixItems.length > 0 && dataPage === 1) {
          results.data.patientList = [...req4ac.prefixItems, ...results.data.patientList];
        }
        resolve(formatList(results));
      })
      .catch((reason) => {
        // dispatch(setToastMessage( { type: "error", message: reason?.response?.data?.ExceptionMessage }));
        var reply = {
          data: {
            patientList: [],
            page: req4ac.page ? req4ac.page : '1',
            pageSize: 10,
            totalRecords: 0,
            totalPages: 0,
            query: req4ac.filter || ''
          }
        };
        resolve(reply);
      });
  });
};

export const getClaims = (dcnguid, query, dispatch, pagesize = 10, page = 1) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const newQuery = `?` + (query ? `&q=${JSON.stringify(query)}` : '');
  const url = `${getApiBase()}/claims/${dcnguid}/${page}/${pagesize}/${newQuery}`;
  return axios
    .get(url)
    .then((results) => {
      return results?.data?.claimList;
    })
    .catch((reason) => {
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getPatients = (query, pagesize = patientPickerPageSize, page = 1) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) return Promise.resolve(getPatientsResponseAction(patientsForAutocomplete));

  const currentState = store.getState();
  const DCNGuid = currentState.user.details.DCNGuid;
  setAuthHeader(currentState);
  const newQuery = `?` + (query ? `&q=${JSON.stringify(query)}` : '');
  const url = `${getApiBase()}/patients/${DCNGuid}/${page}/${pagesize}/${newQuery}`;
  return axios
    .get(url)
    .then((results) => {
      return getPatientsResponseAction(results?.data?.patientList);
    })
    .catch((reason) => {
      store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getPatientInfo = (id) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    const patientInfo = getPatientInfoForTests(id);
    return Promise.resolve(patientInfo);
  }

  const currentState = store.getState();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  setAuthHeader(currentState);

  store.dispatch({ type: patientActions.IS_FETCHING_PATIENTS, results: true });

  return axios
    .get(`${getApiBase()}/patients/${DCNGuid}/${id}`)
    .then((results) => {
      store.dispatch({ type: patientActions.IS_FETCHING_PATIENTS, results: false });
      return formatPatientDetails(results.data);
    })
    .catch((reason) => {
      store.dispatch({ type: patientActions.IS_FETCHING_PATIENTS, results: false });
      store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getPatientInsurancePolicy = (patientGuid, policyGuid) => {
  const currentState = store.getState();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  setAuthHeader(currentState);

  return axios
    .get(`${getApiBase()}/patients/${DCNGuid}/${patientGuid}/extended/${policyGuid}`)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getPatientInsurancePolicies = (patientGuid) => {
  const currentState = store.getState();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  setAuthHeader(currentState);

  return axios
    .get(`${getApiBase()}/patients/${DCNGuid}/${patientGuid}/extended`)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getEClaimLocations = (practitionerGuid, type) => {
  const currentState = store.getState();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  setAuthHeader(currentState);

  // return axios.get(`${getApiBase()}/users/${DCNGuid}/1542875F-6B30-4AFF-BD59-4C9016D33830/locations/${type}`) // For tests
  return axios
    .get(`${getApiBase()}/users/${DCNGuid}/${practitionerGuid}/locations/${type}`)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getPrefs = (dcnguid, userId) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    const prefs = getPrefersForTests(userId);
    return Promise.resolve(prefs);
  }

  const currentState = store.getState();
  setAuthHeader(currentState);

  store.dispatch({ type: prefsActions.IS_GETTING_PREFS, results: true });

  return axios
    .get(`${getApiBase()}/preferences/${dcnguid}/${userId}`)
    .then((results) => {
      store.dispatch({ type: prefsActions.IS_GETTING_PREFS, results: false });
      return results.data;
    })
    .catch((reason) => {
      store.dispatch({ type: prefsActions.IS_GETTING_PREFS, results: false });
      store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getPatientClaims = (patientGuid) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    const patientClaims = getPatientClaimDataForTests(patientGuid);
    return Promise.resolve(patientClaims);
  }

  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const url = `${getApiBase()}/claims/${DCNGuid}/${patientGuid}/claims`;

  return axios
    .get(url)
    .then((results) => {
      const formatWCBClaims = results.data?.WCB?.map((i) => {
        if (i.Reports?.length) {
          return {
            ...i,
            Reports: i.Reports.map((report) => formatWSBCClaimReport(report))
          };
        }

        return i;
      });

      return { ...results.data, WCB: formatWCBClaims };
    })
    .catch((reason) => {
      store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getRecentCodes = (patientGuid = '', type = '') => {
  // Note: if type is omitted, backend returns recent codes for all types (MWIDP9NAVRBT)
  // where M- MSP, W- WSBC, I -ICBC, D- Doctors of BC, 9 - ICND9,
  // N - Nature of injury, A - area of injury, V - visits, R - referrals, B - PBC, T- eClaims

  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    const recentCodes = getMostRecentCodesForTest(patientGuid);
    return Promise.resolve(recentCodes);
  }

  const currentState = store.getState();
  setAuthHeader(currentState);
  const currentDCNGuid = currentState?.user?.details?.DCNGuid;
  const baseURL = `${getApiBase()}/lookup/catalogues/${currentDCNGuid}/recent`;
  const url = new URL(baseURL);
  if (patientGuid) url.pathname += `/${patientGuid}`;
  if (type) url.searchParams.set('type', type);

  return axios
    .get(url)
    .then((results) => {
      const resentCodesWithServiceDates = { ...results.data, serviceDate: recentServiceDates(results.data.visits || []) };
      return resentCodesWithServiceDates;
    })
    .catch((reason) => {
      store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getCurrentPatient = (dcnguid, query, dispatch, pagesize = 10, page = 1) => {
  const currentState = store.getState();
  setAuthHeader(currentState);

  const newQuery = `?` + (query ? `&q=${query}` : '');
  const url = `${getApiBase()}/patients/${dcnguid}/${page}/${pagesize}/${newQuery}`;
  return axios
    .get(url)
    .then((results) => {
      const updatePatientList = results?.data?.patientList?.map((i) => {
        return {
          ...i,
          FullAddress: `${i.AddressLine} ${i.City} ${i.Province} ${i.Zip}`
        };
      });
      return { ...results?.data, patientList: updatePatientList };
    })
    .catch((reason) => {
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getPayors = (dcnguid, query, dispatch, pagesize = 10, page = 1) => {
  const currentState = store.getState();
  setAuthHeader(currentState);

  const newQuery = `?` + (query ? `&q=${query}` : '');
  const url = `${getApiBase()}/payors/${dcnguid}/${page}/${pagesize}/${newQuery}`;
  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getAddress = (query) => {
  const currentState = store.getState();
  setAuthHeader(currentState);

  return axios
    .get('http://localhost:8983/solr/address/select?q=' + query + '*')
    .then((res) => {
      return res.data;
    })
    .catch((reason) => {
      // dispatch(setToastMessage( { type: "error", message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getCodesForCatalog = ({
  catalog,
  page = 1,
  pageSize = invoicesCatalogsDefaultPageSize,
  query = '',
  dcnguid,
  sortby = '',
  speciality = '0',
  type
}) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) return getCodesForCatalogResponceAction(catalog);

  const currentState = store.getState();
  setAuthHeader(currentState);

  const currentType = type && typeof type === 'string' ? type?.charAt(0).toUpperCase() : '';
  const commonUrlParams = `${catalog}/${page}/${pageSize}?sortby=${sortby}&spec=${speciality}&q=${query}&type=${currentType}`;
  const urlParams = dcnguid ? `${dcnguid}/${commonUrlParams}` : commonUrlParams;
  const url = `${getApiBase()}/lookup/catalogues/${urlParams}`;
  return axios
    .get(url)
    .then((res) => {
      return { ...res, data: { ...res.data, codeList: getExactMatchesFirst(query, res.data.codeList) } };
    })
    .catch((reason) => {
      console.log('!!!!getCodesForCatalog');
      store.dispatch(setToastMessage({ type: 'error', message: 'Opps, something went wrong' }));
    });
};

export const Speciaities = [
  { id: 0, service: 'physician', specialty_name: 'GENERAL PRACTITIONER' },
  { id: 1, service: 'dermatologist', specialty_name: 'DERMATOLOGY' },
  { id: 2, service: 'neurologist', specialty_name: 'NEUROLOGY' },
  { id: 3, service: 'psychiatrist', specialty_name: 'PSYCHIATRY' },
  { id: 6, service: 'ophtalmologist', specialty_name: 'OPHTHALMOLOGY' },
  { id: 19, service: 'pediatric care', specialty_name: 'PAEDIATRICS' },
  { id: 16, service: 'NULL', specialty_name: 'RADIOLOGY' },
  { id: 18, service: 'anaesthia', specialty_name: 'ANAESTHESIA' },
  { id: 14, service: 'chiropractic treatment', specialty_name: 'CHIROPRACTORS' },
  { id: 24, service: 'naturopath', specialty_name: 'NATUROPATHS' },
  { id: 5, service: 'physical therapy', specialty_name: 'PHYSICAL THERAPISTS' },
  { id: 38, service: 'podiatrist', specialty_name: 'PODIATRISTS' },
  { id: 39, service: 'optometry', specialty_name: 'OPTOMETRIST' },
  { id: 4, service: 'massage', specialty_name: 'MASSAGE PRACTITIONER' },
  { id: 44, service: 'acupuncture', specialty_name: 'ACUPUNCTURE' },
  { id: 80, service: 'midwife', specialty_name: 'MIDWIVES OF BC' },
  { id: 87, service: 'nurse', specialty_name: 'NURSE PRACTITIONER' }
];

export const SubmissionCodes = [
  { id: 'E', code_name: 'Debit Request' },
  { id: 'A', code_name: 'Over-age Claim' },
  { id: 'W', code_name: 'Declined WSBC Claim' },
  { id: 'X', code_name: 'Declined Claim' },
  { id: 'C', code_name: 'Coverage Problem' },
  { id: 'O', code_name: 'Normal Submission' },
  { id: 'D', code_name: 'Duplicate Claim' }
];
