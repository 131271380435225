import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import GlobalSearch from '../GlobalSearch';
import { Button } from 'primereact/button';
import CreateRecordCard from './CreateRecordCard';

import { routes } from '../../../../routes/routes';
import { elementIDs } from '../../../config/elementIDsConfig';
import { addNewTeleplan } from '../../../claims/helpers/addNewInvoice';
import { t } from '../../../../service/localization/i18n';

const CreateTeleplanClaimsCard = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user.details);
  const { showNewTeleplanButtons } = useSelector((state) => state.core.window);

  const firstVisit = () => {
    addNewTeleplan({ history, firstVisit: true });
  };

  const newTeleplan = (pos = false) => {
    addNewTeleplan({ history, pos });
  };

  // const newWSBCR = () => {
  //   history.push(`${routes.newWSBCReport.path}`);
  // };

  return (
    <CreateRecordCard title="Create Teleplan claims">
      <div className="flex flex-column gap-3">
        <GlobalSearch name="claim" placeholder={t('Enter_office_number_or_sequence_number_of_the_claim_here')} />

        <div className="flex justify-content-around align-items-center gap-2 flex-wrap">
          {user.canBillMSP && (
            <>
              <Button
                id={elementIDs.createTeleplanInvoiceButton}
                className="w-max"
                rounded
                text
                label={t('Teleplan')}
                // icon="pi pi-plus"
                onClick={() => newTeleplan(false)}
              />

              {!showNewTeleplanButtons && (
                <>
                  <Button
                    id={elementIDs.createTeleplanInvoiceButton}
                    className="w-max"
                    rounded
                    text
                    label={t('Picker')}
                    // icon="pi pi-plus"
                    onClick={() => newTeleplan(true)}
                  />

                  <Button
                    className="w-max"
                    rounded
                    text
                    label={t('Batch')}
                    // icon="pi pi-plus"
                    onClick={() => history.push(routes.teleplanGroup.path)}
                  />
                </>
              )}

              <Button
                id={elementIDs.createFirstVisitInvoiceButton}
                className="w-max"
                rounded
                text
                label={t('First_visit')}
                // icon="pi pi-plus"
                onClick={firstVisit}
              />
            </>
          )}

          {/* {user.canBillWCB && (
    <Button
      id={elementIDs.createWSBCReportButton}
      className="w-max"
      rounded
      text
      label={t('WSBC_Report')}
      icon="pi pi-plus"
      onClick={newWSBCR}
    />
  )} */}
        </div>
      </div>
    </CreateRecordCard>
  );
};

export default CreateTeleplanClaimsCard;
