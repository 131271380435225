import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CashFlowCard from './CashFlowCard';
import { getCashFlow, setCashFlowQuery } from '../../../cashFlow/actions/cashFlow.axtion.creators';
import { currencyFormat } from '../../../utils/currencyFormat';
import { Chart } from 'primereact/chart';
import { colors } from '../../../config/stylesConfig';
import moment from 'moment';

const TodayCard = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const query = useSelector((state) => state.cashFlow.queryParams?.query);

  const fetchData = async () => {
    setLoading(true);
    const responseData = await getCashFlow({ type: 1 });
    setLoading(false);
    setData(responseData);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    const periodFrom = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
    const periodTo = moment().endOf('day').format('YYYY-MM-DD');
    dispatch(setCashFlowQuery({ ...query, PeriodFrom: periodFrom, PeriodTo: periodTo }));
  };

  const chartData = useMemo(() => {
    const dateFormat = 'MMM Do YYYY';
    const items = data?.Items;
    const totalBilled = items?.reduce((acc, item) => acc + item.TotalBilled, 0);
    const labels = items
      ?.map((i) => moment(i.PeriodStart).format(dateFormat))
      // Sort the labels by date using moment
      .sort((a, b) => moment(a, dateFormat).toDate() - moment(b, dateFormat).toDate());

    return {
      labels,
      datasets: [
        {
          id: 'billed',
          label: `Billed (${currencyFormat(totalBilled)})`,
          backgroundColor: colors.infoDark,
          borderColor: colors.infoDark,
          data: items?.map((i) => i?.TotalBilled)
        }
      ]
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const options = useMemo(() => {
    return {
      responsive: true, // Make the chart responsive
      maintainAspectRatio: false, // Don't lock the aspect ratio
      plugins: {
        legend: {
          display: false // Hide legend
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const dataset = tooltipItem.dataset;
              const dataValue = dataset.data[tooltipItem.dataIndex];

              // Check if it's the Billed dataset
              if (dataset.id === 'billed') {
                return `Billed: ${currencyFormat(dataValue)}`;
              }
              // Check if it's the Paid dataset
              if (dataset.id === 'paid') {
                return `Paid: ${currencyFormat(dataValue)}`;
              }

              return `${dataset.label}: $${dataValue}`;
            }
          }
        }
      },
      scales: {
        x: {
          display: false // Hide x-axis
        },
        y: {
          display: false // Hide y-axis
        }
      },
      elements: {
        line: {
          borderWidth: 2 // Adjust line thickness
        }
      }
    };
  }, []);

  // Get today's date in the same format as your data
  const today = moment().startOf('day');
  // Find the item with today's date
  const itemWithTodaysDate = data?.Items?.find((item) => moment(item.PeriodStart).isSame(today, 'day'));

  return (
    <CashFlowCard title="Today" subtitle="Billed (CAD)" amount={itemWithTodaysDate?.TotalBilled} data={data} loading={loading} onClick={onClick}>
      {itemWithTodaysDate?.TotalBilled ? (
        <Chart className="w-full h-full" type="bar" data={chartData} options={options} />
      ) : (
        <img src="assets/images/doctor_meditating_500x500.png" alt="Meditating Doctor" width="108" height="112" />
      )}
    </CashFlowCard>
  );
};

export default TodayCard;
