import React from 'react';
import CommonCard from '../../../common/components/CommonCard';

const CreateRecordCard = ({ children, title }) => {
  return (
    <div className="col-12 sm:col-6 lg:col-12 xl:col-6">
      <CommonCard title={title}>{children}</CommonCard>
    </div>
  );
};

export default CreateRecordCard;
