import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';

import { PatientAutoComplete } from '../../patients/components/commonElements/PatientAutoComplete';
import InputWithReset from '../../common/components/InputWithReset';

import { setFilter, setMultiValueFilter } from '../../utils/legoSortFilter';
import { dropdownScrollHeight } from '../../config/stylesConfig';
import { getPatientArrayCSV, filterByYear } from '../../../Helper';
import { setClaimFilters, setClaimFiltersWithRequest } from '../../claims/actions/claims.action.creators';
import { t } from '../../../service/localization/i18n';
import classes from './ClaimFilters.module.scss';
import cx from 'classnames';
import moment from 'moment';
import { provinces_list } from '../../config/dropdownListsConfig';
import { submissionCodes } from '../helpers/mapper';
import { InputText } from 'primereact/inputtext';
import { debounce } from 'lodash';
import { location_codes } from '../../common/contants';

const ClaimFilters = ({ statuses, dataSource, selectedCodes, filters }) => {
  // may use in future to display only available statuses
  // const filteredStatuses = useSelector(state => state.claims.statusList);
  // const uniqueStatuses = filteredStatuses
  // ? Helper.dropdownUnique(filteredStatuses)
  // : [];
  const dispatch = useDispatch();

  const [customPeriod, setCustomPeriod] = useState({ from: '', to: '' });
  const [submissionCustomPeriod, setSubmissionCustomPeriod] = useState({
    submissionFrom: '',
    submissionTo: ''
  });
  const [remittanceCustomPeriod, setRemittanceCustomPeriod] = useState({
    remittanceFrom: '',
    remittanceTo: ''
  });
  const [invalidFields, setInvalidFields] = useState({
    from: false,
    to: false,
    submissionFrom: false,
    submissionTo: false,
    remittanceFrom: false,
    remittanceTo: false
  });
  const [diffError, setDiffError] = useState(false);
  const [submissionDiffError, setSubmissionDiffError] = useState(false);
  const [remittanceDiffError, setRemittanceDiffError] = useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment(filters.Comment || '');
  }, [filters.Comment]);

  useEffect(() => {
    !filters.PeriodFrom && !filters.PeriodTo && setDiffError(false);
    dateDiff(filters.PeriodFrom, filters.PeriodTo) < 0 ? setDiffError(true) : setDiffError(false);
    setCustomPeriod({
      from: filters.PeriodFrom || '',
      to: filters.PeriodTo || ''
    });
  }, [filters.PeriodFrom, filters.PeriodTo]);

  useEffect(() => {
    customPeriod.from === '' && setInvalidFields({ ...invalidFields, from: false });
    customPeriod.to === '' && setInvalidFields({ ...invalidFields, to: false });
  }, [customPeriod.from === '', customPeriod.to === '']);

  useEffect(() => {
    !filters.SubmissionPeriodFrom && !filters.SubmissionPeriodTo && setSubmissionDiffError(false);
    submissionDateDiff(filters.SubmissionPeriodFrom, filters.SubmissionPeriodTo) < 0 ? setSubmissionDiffError(true) : setSubmissionDiffError(false);
    setSubmissionCustomPeriod({
      submissionFrom: filters.SubmissionPeriodFrom || '',
      submissionTo: filters.SubmissionPeriodTo || ''
    });
  }, [filters.SubmissionPeriodFrom, filters.SubmissionPeriodTo]);

  useEffect(() => {
    !filters.RemittancePeriodFrom && !filters.RemittancePeriodTo && setRemittanceDiffError(false);
    remittanceDateDiff(filters.RemittancePeriodFrom, filters.RemittancePeriodTo) < 0 ? setRemittanceDiffError(true) : setRemittanceDiffError(false);
    setRemittanceCustomPeriod({
      remittanceFrom: filters.RemittancePeriodFrom || '',
      remittanceTo: filters.RemittancePeriodTo || ''
    });
  }, [filters.RemittancePeriodFrom, filters.RemittancePeriodTo]);

  useEffect(() => {
    submissionCustomPeriod.submissionFrom === '' && setInvalidFields({ ...invalidFields, submissionFrom: false });
    submissionCustomPeriod.submissionTo === '' && setInvalidFields({ ...invalidFields, submissionTo: false });
  }, [submissionCustomPeriod.submissionFrom === '', submissionCustomPeriod.submissionTo === '']);

  const filterParameters = { statuses, filters, dispatch };
  const docList = dataSource && dataSource.find((x) => x.FieldName === 'DoctorGuid') ? dataSource.find((x) => x.FieldName === 'DoctorGuid') : {};
  const payList = dataSource && dataSource.find((x) => x.FieldName === 'PayeeNumber') ? dataSource.find((x) => x.FieldName === 'PayeeNumber') : {};
  const ruralList = dataSource && dataSource.find((x) => x.FieldName === 'SCCode') ? dataSource.find((x) => x.FieldName === 'SCCode') : {};
  const locationList = [{ value: '', label: t('Any') }, ...location_codes];

  const remList =
    dataSource && dataSource.find((x) => x.FieldName === 'RemittanceGuid') ? dataSource.find((x) => x.FieldName === 'RemittanceGuid').options : [];

  const submissionCodesList = [{ value: '-2', label: t('All'), text: t('All') }, ...submissionCodes];

  const remSelected = filters && filters.RemittanceGuid ? filters.RemittanceGuid : '-2';
  const remPeriod = filters && filters.RemittanceDate ? filters.RemittanceDate : '-2';

  const remDates = remPeriod === 1 || remPeriod === 2 ? filterByYear(remPeriod, remList) : null;

  const showServiceDate = dataSource && dataSource.find((x) => x.FieldName === 'ServiceDate');
  const showSubmissioneDate = dataSource && dataSource.find((x) => x.FieldName === 'SubmissionDate');
  const showRemittanceDate = dataSource && dataSource.find((x) => x.FieldName === 'RemittanceDate');

  const isValidDate = (date) => {
    return moment(date, 'YYYY/MM/DD', true).isValid() && date <= '2222/12/31' && date >= '1900/01/01';
  };

  const dateDiff = (from, to) => {
    if (filters.Period === '7' && from && from.length > 0 && to && to.length > 0) {
      return moment(to).diff(from);
    } else return 0;
  };

  const submissionDateDiff = (from, to) => {
    if (filters.SubmissionPeriod === '7' && from && from.length > 0 && to && to.length > 0) {
      return moment(to).diff(from);
    } else return 0;
  };

  const remittanceDateDiff = (from, to) => {
    if (filters.RemittancePeriod === '7' && from && from.length > 0 && to && to.length > 0) {
      return moment(to).diff(from);
    } else return 0;
  };

  const setCustomPeriodFilter = (e, isSingleDate) => {
    let value = e.target && e.target.value ? e.target.value : e.value || '';
    const fieldName = e.target && e.target.name ? e.target.name : '';
    const field = e.target && e.target.id ? e.target.id : '';
    const updatedRange = { from: customPeriod.from, to: customPeriod.to };
    const filteredField = filters[fieldName];
    updatedRange[field] = value;
    if (isSingleDate) updatedRange.to = value;
    const suspect = value.replace(/\//g, '').replace(/-/g, '').replace(/ /g, '').replace(/M/g, '').replace(/D/g, '');

    setCustomPeriod(updatedRange);

    if (suspect.length === 8) {
      isValidDate(value) ? setInvalidFields({ ...invalidFields, [field]: false }) : setInvalidFields({ ...invalidFields, [field]: true });
    }

    if (isValidDate(value) || value.length === 0) {
      setInvalidFields({ ...invalidFields, [field]: false });
      if (filteredField !== value) {
        if (isSingleDate) {
          setFilter(
            {
              value: {
                PeriodFrom: value,
                PeriodTo: value,
                ServiceDate: value,
                Period: '8'
              },
              field: 'from'
            },
            { ...filterParameters, isSingleDate }
          );
        } else {
          setFilter(e, { ...filterParameters });
        }
      }
    }
  };

  const setSubmissionCustomPeriodFilter = (e, isSingleDate) => {
    let value = e.target && e.target.value ? e.target.value : e.value || '';
    const fieldName = e.target && e.target.name ? e.target.name : '';
    const field = e.target && e.target.id ? e.target.id : '';
    const updatedRange = {
      submissionFrom: submissionCustomPeriod.submissionFrom,
      submissionTo: submissionCustomPeriod.submissionTo
    };
    const filteredField = filters[fieldName];
    updatedRange[field] = value;
    if (isSingleDate) updatedRange.submissionTo = value;
    const suspect = value.replace(/\//g, '').replace(/-/g, '').replace(/ /g, '').replace(/M/g, '').replace(/D/g, '');

    setSubmissionCustomPeriod(updatedRange);

    if (suspect.length === 8) {
      isValidDate(value) ? setInvalidFields({ ...invalidFields, [field]: false }) : setInvalidFields({ ...invalidFields, [field]: true });
    }

    if (isValidDate(value) || value.length === 0) {
      setInvalidFields({ ...invalidFields, [field]: false });
      if (filteredField !== value) {
        if (isSingleDate) {
          setFilter(
            {
              value: {
                SubmissionPeriodFrom: value,
                SubmissionPeriodTo: value,
                SubmissionDate: value,
                SubmissionPeriod: '8'
              },
              field: 'submissionFrom'
            },
            { ...filterParameters, isSingleDate }
          );
        } else {
          setFilter(e, { ...filterParameters });
        }
      }
    }
  };

  const setRemittanceCustomPeriodFilter = (e, isSingleDate) => {
    let value = e.target && e.target.value ? e.target.value : e.value || '';
    const fieldName = e.target && e.target.name ? e.target.name : '';
    const field = e.target && e.target.id ? e.target.id : '';
    const updatedRange = {
      remittanceFrom: remittanceCustomPeriod.remittanceFrom,
      remittanceTo: remittanceCustomPeriod.remittanceTo
    };
    const filteredField = filters[fieldName];
    updatedRange[field] = value;
    if (isSingleDate) updatedRange.remittanceTo = value;
    const suspect = value.replace(/\//g, '').replace(/-/g, '').replace(/ /g, '').replace(/M/g, '').replace(/D/g, '');

    setRemittanceCustomPeriod(updatedRange);

    if (suspect.length === 8) {
      isValidDate(value) ? setInvalidFields({ ...invalidFields, [field]: false }) : setInvalidFields({ ...invalidFields, [field]: true });
    }

    if (isValidDate(value) || value.length === 0) {
      setInvalidFields({ ...invalidFields, [field]: false });
      if (filteredField !== value) {
        if (isSingleDate) {
          setFilter(
            {
              value: {
                RemittancePeriodFrom: value,
                RemittancePeriodTo: value,
                RemittanceDate: value,
                RemittancePeriod: '8'
              },
              field: 'remittanceFrom'
            },
            { ...filterParameters, isSingleDate }
          );
        } else {
          setFilter(e, { ...filterParameters });
        }
      }
    }
  };

  const handleSwapSubmission = () => {
    dispatch(
      setClaimFilters({
        ...filters,
        SubmissionPeriodFrom: filters.SubmissionPeriodTo,
        SubmissionPeriodTo: filters.SubmissionPeriodFrom
      })
    );
    setFilter({ value: filters.SubmissionPeriodFrom, field: 'submissionTo' }, filterParameters);
  };

  const handleSwapRemittance = () => {
    dispatch(
      setClaimFilters({
        ...filters,
        RemittancePeriodFrom: filters.RemittancePeriodTo,
        RemittancePeriodTo: filters.RemittancePeriodFrom
      })
    );
    setFilter({ value: filters.RemittancePeriodFrom, field: 'remittanceTo' }, filterParameters);
  };

  const handleSwap = () => {
    dispatch(
      setClaimFilters({
        ...filters,
        PeriodFrom: filters.PeriodTo,
        PeriodTo: filters.PeriodFrom
      })
    );
    setFilter({ value: filters.PeriodFrom, field: 'to' }, filterParameters);
  };

  const setOnBlur = (e) => {
    const value = e.target && e.target.value ? e.target.value : e.value || '';
    const field = e.target && e.target.id ? e.target.id : '';

    if (isValidDate(value) || value.length === 0) {
      setInvalidFields({ ...invalidFields, [field]: false });
    } else {
      setInvalidFields({ ...invalidFields, [field]: true });
    }
  };

  const dropdownValue = (field, defaultValue) => {
    let value = '-2'; // default is 'All'
    const suspect = filters[field];

    if (defaultValue && !!suspect === false) return defaultValue;

    if (field === 'Period' && !!suspect === false) {
      value = '-2';
    }

    if (field === 'SubmissionPeriod' && !!suspect === false) {
      value = '-2';
    }

    if (field === 'RemittancePeriod' && !!suspect === false) {
      value = '-2';
    }

    if (field === 'LocationCode') {
      if (!!suspect === false) {
        return '';
      } else {
        return suspect;
      }
    }

    if (field === 'StatusText') {
      const _status = statuses.allStatuses.find((i) => i.label === filters.StatusText);
      return (value = _status ? _status.value : '-2');
    }

    if (suspect && defaultValue && typeof defaultValue === typeof suspect) {
      return suspect;
    }

    if (field === 'PatientGuid' && suspect) {
      if (suspect.indexOf(',') > -1) {
        value = getPatientArrayCSV(suspect);
      } else {
        const parts = suspect.split('|');
        if (parts.length > 1) {
          value = { PatientGuid: parts[0], value: parts[1] };
        }
      }
    }

    if (field === 'PatientGuid' && !!suspect === false) {
      value = null;
    }

    return value;
  };

  const debouncedCommentFilterRequest = useCallback(
    debounce((value) => {
      dispatch(setClaimFiltersWithRequest(value, 'Comment'));
    }, 1000),
    []
  );

  return (
    <>
      {showServiceDate && (
        <div className="col-12">
          <label>{t('Service_date')}</label>
          <InputWithReset
            fieldId="Period"
            filterValue={filters.Period}
            customPeriod={customPeriod}
            invalidFields={invalidFields}
            setCustomPeriod={setCustomPeriod}
            setInvalidFields={setInvalidFields}
          >
            <Dropdown
              id="Period"
              autoWidth={true}
              className={classes.dropdown}
              options={statuses.reportPeriods}
              value={dropdownValue('Period', '-2')}
              scrollHeight={dropdownScrollHeight()}
              onChange={(e) =>
                setFilter(e, {
                  customPeriod,
                  setCustomPeriod,
                  ...filterParameters
                })
              }
            />
          </InputWithReset>
        </div>
      )}

      {filters.Period === '7' && (
        <div className="col-12">
          <div className="col-12 px-0">
            <label>{t('From')}</label>
            <InputWithReset
              fieldId="PeriodFrom"
              filterValue={filters.PeriodFrom}
              setCustomPeriod={setCustomPeriod}
              customPeriod={customPeriod}
              invalidFields={invalidFields}
              setDiffError={setDiffError}
              setInvalidFields={setInvalidFields}
            >
              <InputMask
                id="from"
                name="PeriodFrom"
                className={cx(classes.dropdown, (invalidFields.from || diffError) && 'p-invalid p-error')}
                mask="9999/99/99"
                slotChar="YYYY/MM/DD"
                placeholder={t('YYYY-MM-DD')}
                value={customPeriod.from}
                autoClear={false}
                onBlur={(e) => setOnBlur(e)}
                onChange={(e) => setCustomPeriodFilter(e)}
              />
            </InputWithReset>
            <div className="p-error">{invalidFields.from && t('Wrong entry')}</div>
          </div>

          <div className="col-12 px-0">
            <label>{t('To')}</label>
            <InputWithReset
              fieldId="PeriodTo"
              filterValue={filters.PeriodTo}
              setCustomPeriod={setCustomPeriod}
              customPeriod={customPeriod}
              invalidFields={invalidFields}
              setDiffError={setDiffError}
              setInvalidFields={setInvalidFields}
            >
              <InputMask
                id="to"
                name="PeriodTo"
                className={cx(classes.dropdown, (invalidFields.to || diffError) && 'p-invalid p-error')}
                mask="9999/99/99"
                slotChar="YYYY/MM/DD"
                placeholder={t('YYYY-MM-DD')}
                value={customPeriod.to}
                autoClear={false}
                onBlur={(e) => setOnBlur(e)}
                onChange={(e) => setCustomPeriodFilter(e)}
              />
            </InputWithReset>
            <div>
              {invalidFields.to && <span className="small-text p-error">{t('Wrong entry')}</span>}
              {diffError && (
                <>
                  <span className="small-text p-error">{t('End Date is earlier than the Start Date')}</span>
                  <Button className={cx('p-button-text pt-0 pb-0 pl-2 pr-2 ml-2')} icon="pi pi-sync" label={`${t('Swap')}?`} onClick={handleSwap} />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {filters.Period === '8' && (
        <div className="col-12">
          <div className="col-12 px-0">
            <label>{t('Single_date')}</label>
            <InputWithReset
              fieldId="PeriodFrom"
              filterValue={filters.PeriodFrom}
              setCustomPeriod={setCustomPeriod}
              customPeriod={customPeriod}
              invalidFields={invalidFields}
              setDiffError={setDiffError}
              setInvalidFields={setInvalidFields}
            >
              <InputMask
                id="from"
                name="PeriodFrom"
                className={cx(classes.dropdown, (invalidFields.from || diffError) && 'p-invalid p-error')}
                mask="9999/99/99"
                slotChar="YYYY/MM/DD"
                placeholder={t('YYYY-MM-DD')}
                value={customPeriod.from}
                autoClear={false}
                onBlur={(e) => setOnBlur(e)}
                onChange={(e) => setCustomPeriodFilter(e, true)}
              />
            </InputWithReset>
            <div className="p-error">{invalidFields.from && t('Wrong entry')}</div>
          </div>
        </div>
      )}

      <div className="col-12">
        <label>{t('Type')}</label>
        <InputWithReset fieldId="RecordType" filterValue={filters.RecordType}>
          <Dropdown
            id="RecordType"
            className={classes.dropdown}
            options={statuses.reportTypes0}
            value={dropdownValue('RecordType', '-2')}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => setFilter(e, filterParameters)}
            autoWidth={true}
          />
        </InputWithReset>
      </div>

      <div className="col-12">
        <label>{t('Status')}</label>
        <InputWithReset
          fieldId={'StatusText'}
          filterValue={filters.StatusText === 'All Statuses' || filters.StatusText === 'All' ? '-2' : filters.StatusText}
        >
          <Dropdown
            id="StatusText"
            className={classes.dropdown}
            options={statuses.allStatuses}
            value={dropdownValue('StatusText', '-2')}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => setFilter(e, filterParameters)}
            autoWidth={true}
          />
        </InputWithReset>
      </div>

      {/* <div className="col-12" >
        <label>{t("Province")}</label>
        <InputWithReset fieldId={'Province'} filterValue={filters.Province} >
          <Dropdown
            id='Province'
            className={classes.dropdown}
            options={[{ label: t("All"), value: '-2' }, ...provinces_list]}
            value={dropdownValue('Province', '-2')}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => setFilter(e, filterParameters)}
            autoWidth={true}
          />
        </InputWithReset>
      </div> */}

      {docList.options && (
        <div className="col-12">
          <label>{t('Practitioner')}</label>
          <InputWithReset fieldId="DoctorGuid" filterValue={filters.DoctorGuid}>
            <Dropdown
              id="DoctorGuid"
              className={classes.dropdown}
              options={docList.options}
              value={dropdownValue('DoctorGuid', '-2')}
              scrollHeight={dropdownScrollHeight()}
              onChange={(e) => setFilter(e, filterParameters)}
              autoWidth={true}
            />
          </InputWithReset>
        </div>
      )}

      {payList.options && (
        <div className="col-12">
          <label>{t('Payment assignment')}</label>
          <InputWithReset fieldId="PayeeNumber" filterValue={filters.PayeeNumber}>
            <Dropdown
              id="PayeeNumber"
              className={classes.dropdown}
              options={payList.options}
              value={dropdownValue('PayeeNumber')}
              scrollHeight={dropdownScrollHeight()}
              onChange={(e) => setFilter(e, filterParameters)}
              autoWidth={true}
            />
          </InputWithReset>
        </div>
      )}

      {ruralList.options && (
        <div className="col-12">
          <label>{t('Rural_code')}</label>
          <InputWithReset fieldId="SCCode" filterValue={filters.SCCode}>
            <Dropdown
              id="SCCode"
              className={classes.dropdown}
              options={ruralList.options}
              value={dropdownValue('SCCode', '-2')}
              scrollHeight={dropdownScrollHeight()}
              onChange={(e) => setFilter(e, filterParameters)}
              autoWidth={true}
            />
          </InputWithReset>
        </div>
      )}

      <div className="col-12">
        <label>{t('Location_code')}</label>
        <InputWithReset fieldId="LocationCode" filterValue={filters.LocationCode}>
          <Dropdown
            id="LocationCode"
            className={classes.dropdown}
            options={locationList}
            optionLabel="label"
            value={dropdownValue('LocationCode', '')}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => setFilter(e, filterParameters)}
            autoWidth={true}
          />
        </InputWithReset>
      </div>

      <div className="col-12">
        <label>{t('Submission_Code')}</label>
        <InputWithReset fieldId="SubmissionCode" filterValue={filters.SubmissionCode}>
          <Dropdown
            id="SubmissionCode"
            className={classes.dropdown}
            options={submissionCodesList}
            value={dropdownValue('SubmissionCode', '-2')}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => setFilter(e, filterParameters)}
            autoWidth={true}
          />
        </InputWithReset>
      </div>

      {showSubmissioneDate && (
        <div className="col-12">
          <label>{t('Submission Date.1')}</label>
          <InputWithReset
            fieldId="SubmissionPeriod"
            filterValue={filters.SubmissionPeriod}
            setCustomPeriod={setSubmissionCustomPeriod}
            customPeriod={submissionCustomPeriod}
            invalidFields={invalidFields}
            setDiffError={setDiffError}
            setInvalidFields={setInvalidFields}
          >
            <Dropdown
              id="SubmissionPeriod"
              className={classes.dropdown}
              options={statuses.remPeriods}
              value={dropdownValue('SubmissionPeriod', '-2')}
              scrollHeight={dropdownScrollHeight()}
              onChange={(e) =>
                setFilter(e, {
                  submissionCustomPeriod,
                  setSubmissionCustomPeriod,
                  ...filterParameters
                })
              }
              autoWidth={true}
            />
          </InputWithReset>
        </div>
      )}

      {/* Custom period for Submission date */}
      {filters.SubmissionPeriod === '7' && (
        <div className="col-12">
          <div className="col-12 px-0">
            <label>{t('From')}</label>
            <InputWithReset
              fieldId="SubmissionPeriodFrom"
              filterValue={filters.SubmissionPeriodFrom}
              setCustomPeriod={setSubmissionCustomPeriod}
              customPeriod={submissionCustomPeriod}
              invalidFields={invalidFields}
              setDiffError={setDiffError}
              setInvalidFields={setInvalidFields}
            >
              <InputMask
                id="submissionFrom"
                name="SubmissionPeriodFrom"
                className={cx(classes.dropdown, (invalidFields.submissionFrom || submissionDiffError) && 'p-invalid p-error')}
                mask="9999/99/99"
                slotChar="YYYY/MM/DD"
                placeholder={t('YYYY-MM-DD')}
                value={submissionCustomPeriod.submissionFrom}
                autoClear={false}
                onBlur={(e) => setOnBlur(e)}
                onChange={(e) => setSubmissionCustomPeriodFilter(e)}
              />
            </InputWithReset>
            <div className="p-error">{invalidFields.submissionFrom && t('Wrong entry')}</div>
          </div>

          <div className="col-12 px-0">
            <label>{t('To')}</label>
            <InputWithReset
              fieldId="SubmissionPeriodTo"
              filterValue={filters.SubmissionPeriodTo}
              setCustomPeriod={setSubmissionCustomPeriod}
              customPeriod={submissionCustomPeriod}
              invalidFields={invalidFields}
              setDiffError={setDiffError}
              setInvalidFields={setInvalidFields}
            >
              <InputMask
                id="submissionTo"
                name="SubmissionPeriodTo"
                className={cx(classes.dropdown, (invalidFields.submissionTo || submissionDiffError) && 'p-invalid p-error')}
                mask="9999/99/99"
                slotChar="YYYY/MM/DD"
                placeholder={t('YYYY-MM-DD')}
                value={submissionCustomPeriod.submissionTo}
                autoClear={false}
                onBlur={(e) => setOnBlur(e)}
                onChange={(e) => setSubmissionCustomPeriodFilter(e)}
              />
            </InputWithReset>
            <div>
              {invalidFields.submissionTo && <span className="small-text p-error">{t('Wrong entry')}</span>}
              {submissionDiffError && (
                <>
                  <span className="small-text p-error">{t('End Date is earlier than the Start Date')}</span>
                  <Button
                    className={cx('p-button-text pt-0 pb-0 pl-2 pr-2 ml-2')}
                    icon="pi pi-sync"
                    label={`${t('Swap')}?`}
                    onClick={handleSwapSubmission}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {filters.SubmissionPeriod === '8' && (
        <div className="col-12">
          <div className="col-12 px-0">
            <label>{t('Single_date')}</label>
            <InputWithReset
              fieldId="SubmissionPeriodFrom"
              filterValue={filters.SubmissionPeriodFrom}
              setCustomPeriod={setSubmissionCustomPeriod}
              customPeriod={submissionCustomPeriod}
              invalidFields={invalidFields}
              setDiffError={setDiffError}
              setInvalidFields={setInvalidFields}
            >
              <InputMask
                id="submissionFrom"
                name="SubmissionPeriodFrom"
                className={cx(classes.dropdown, (invalidFields.submissionFrom || diffError) && 'p-invalid p-error')}
                mask="9999/99/99"
                slotChar="YYYY/MM/DD"
                placeholder={t('YYYY-MM-DD')}
                value={submissionCustomPeriod.submissionFrom}
                autoClear={false}
                onBlur={(e) => setOnBlur(e)}
                onChange={(e) => setSubmissionCustomPeriodFilter(e, true)}
              />
            </InputWithReset>
            <div className="p-error">{invalidFields.submissionFrom && t('Wrong entry')}</div>
          </div>
        </div>
      )}

      {showRemittanceDate && (
        <div className="col-12">
          <label>{t('Remittance Date')}</label>
          <InputWithReset
            fieldId="RemittancePeriod"
            filterValue={filters.RemittancePeriod}
            setCustomPeriod={setRemittanceCustomPeriod}
            customPeriod={remittanceCustomPeriod}
            invalidFields={invalidFields}
            setDiffError={setDiffError}
            setInvalidFields={setInvalidFields}
          >
            <Dropdown
              id="RemittancePeriod"
              className={classes.dropdown}
              options={statuses.remPeriods}
              value={dropdownValue('RemittancePeriod', '-2')}
              scrollHeight={dropdownScrollHeight()}
              onChange={(e) =>
                setFilter(e, {
                  remittanceCustomPeriod,
                  setRemittanceCustomPeriod,
                  ...filterParameters
                })
              }
              autoWidth={true}
            />
          </InputWithReset>
        </div>
      )}

      {/* Custom period for Remittance date */}
      {filters.RemittancePeriod === '7' && (
        <div className="col-12">
          <div className="col-12 px-0">
            <label>{t('From')}</label>
            <InputWithReset
              fieldId="RemittancePeriodFrom"
              filterValue={filters.RemittancePeriodFrom}
              setCustomPeriod={setRemittanceCustomPeriod}
              customPeriod={remittanceCustomPeriod}
              invalidFields={invalidFields}
              setDiffError={setDiffError}
              setInvalidFields={setInvalidFields}
            >
              <InputMask
                id="remittanceFrom"
                name="RemittancePeriodFrom"
                className={cx(classes.dropdown, (invalidFields.remittanceFrom || remittanceDiffError) && 'p-invalid p-error')}
                mask="9999/99/99"
                slotChar="YYYY/MM/DD"
                placeholder={t('YYYY-MM-DD')}
                value={remittanceCustomPeriod.remittanceFrom}
                autoClear={false}
                onBlur={(e) => setOnBlur(e)}
                onChange={(e) => setRemittanceCustomPeriodFilter(e)}
              />
            </InputWithReset>
            <div className="p-error">{invalidFields.remittanceFrom && t('Wrong entry')}</div>
          </div>

          <div className="col-12 px-0">
            <label>{t('To')}</label>
            <InputWithReset
              fieldId="RemittancePeriodTo"
              filterValue={filters.RemittancePeriodTo}
              setCustomPeriod={setRemittanceCustomPeriod}
              customPeriod={remittanceCustomPeriod}
              invalidFields={invalidFields}
              setDiffError={setDiffError}
              setInvalidFields={setInvalidFields}
            >
              <InputMask
                id="remittanceTo"
                name="RemittancePeriodTo"
                className={cx(classes.dropdown, (invalidFields.remittanceTo || remittanceDiffError) && 'p-invalid p-error')}
                mask="9999/99/99"
                slotChar="YYYY/MM/DD"
                placeholder={t('YYYY-MM-DD')}
                value={remittanceCustomPeriod.remittanceTo}
                autoClear={false}
                onBlur={(e) => setOnBlur(e)}
                onChange={(e) => setRemittanceCustomPeriodFilter(e)}
              />
            </InputWithReset>
            <div>
              {invalidFields.remittanceTo && <span className="small-text p-error">{t('Wrong entry')}</span>}
              {remittanceDiffError && (
                <>
                  <span className="small-text p-error">{t('End Date is earlier than the Start Date')}</span>
                  <Button
                    className={cx('p-button-text pt-0 pb-0 pl-2 pr-2 ml-2')}
                    icon="pi pi-sync"
                    label={`${t('Swap')}?`}
                    onClick={handleSwapRemittance}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {showRemittanceDate && remDates && (
        <div className="col-12">
          <label>{t('Remittance Date')}</label>
          <InputWithReset fieldId="RemittanceGuid" filterValue={filters.RemittanceGuid}>
            <Dropdown
              id="RemittanceGuid"
              className={classes.dropdown}
              options={remDates}
              value={remSelected}
              scrollHeight={dropdownScrollHeight()}
              onChange={(e) => setFilter(e, filterParameters)}
              autoWidth={true}
            />
          </InputWithReset>
        </div>
      )}

      {/* here start autocompletes */}
      {/* <div className="col-12">
        <FiltersCodePicker
          name="FeeCode"
          codeType="feecodes"
          placeholder={t("Search_for_codes")}
          readOnly={false}
          showCodeDescription={true}
          showIconCodeDescription={false}
          label={t("Fee Code")}
          value={filters.FeeCode}
          selectedCodes={selectedCodes.FeeCode}
          onCodeSelected={(e) => setMultiValueFilter(e, 'FeeCode', filterParameters)}
        />
      </div>

      <div className="col-12">
        <FiltersCodePicker
          name="ICD9Code"
          codeType="icd9"
          readOnly={false}
          showCodeDescription={true}
          showIconCodeDescription={false}
          label={t("Diagnostic Code")}
          placeholder={t("Search_for_codes")}
          value={filters.ICD9Code}
          selectedCodes={selectedCodes.ICD9Code}
          onCodeSelected={(e) => setMultiValueFilter(e, 'ICD9Code', filterParameters)}
        />
      </div> */}

      <div className="col-12">
        <label>{t('Patient.1')}</label>
        <InputWithReset fieldId="PatientGuid" filterValue={filters.PatientGuid}>
          <PatientAutoComplete
            value={dropdownValue('PatientGuid')}
            placeholder={t('Name_PHN_DOB.1')}
            onChange={(e) => setFilter(e, filterParameters)}
          />
        </InputWithReset>
      </div>

      <div className="col-12">
        <label>{t('Comment')}</label>
        <InputWithReset fieldId="Comment" filterValue={filters.Comment} onReset={() => setComment('')}>
          <InputText
            className="w-full"
            value={comment}
            id="Comment"
            placeholder={t('Search_by_comment')}
            onChange={(e) => {
              const value = e.target.value;
              setComment(value);
              debouncedCommentFilterRequest(value);
            }}
          />
        </InputWithReset>
      </div>
    </>
  );
};

export default ClaimFilters;
