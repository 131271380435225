import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import CreateRecordCard from './CreateRecordCard';
import GlobalSearch from '../GlobalSearch';
import { Button } from 'primereact/button';

import { elementIDs } from '../../../config/elementIDsConfig';
import { addNewPrivate } from '../../../claims/helpers/addNewInvoice';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { t } from '../../../../service/localization/i18n';

const CreatePrivateInvoiceCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const newPrivate = () => {
    addNewPrivate({ history });
  };

  const invoiceThirdParty = () => {
    dispatch(setToastMessage({ type: 'info', message: 'Future Release', lifeTime: 3000 }));
  };

  return (
    <CreateRecordCard title="Create Private Invoice">
      <div className="flex flex-column gap-3">
        <GlobalSearch name="private" placeholder={t('Enter_office_number_or_sequence_number_of_the_claim_here')} />

        <div className="flex align-items-start gap-2">
          <Button
            id={elementIDs.createPrivateInvoiceButton}
            className="w-max"
            rounded
            text
            // icon="pi pi-plus"
            label={t('Patient.1')}
            onClick={newPrivate}
          />
          <Button
            className="w-max"
            rounded
            text
            // icon="pi pi-plus"
            label={t('third_party')}
            onClick={invoiceThirdParty}
          />
        </div>
      </div>
    </CreateRecordCard>
  );
};

export default CreatePrivateInvoiceCard;
