import React from 'react';
import { BlockUI } from 'primereact/blockui';
import ChartCardTitle from './ChartCardTitle';

const ChartCard = ({ children, title, loading, isChartDataEmpty }) => {
  return (
    <div className="card mb-0">
      {
        typeof title === 'string' ? (
          <ChartCardTitle title={title} />
        ) : typeof title === 'function' ? (
          title() // If title is a function, execute it to get the JSX
        ) : (
          title
        ) // If it's already JSX, render it directly
      }

      <BlockUI blocked={loading} template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>}>
        {isChartDataEmpty ? (
          <div className="h-15rem flex flex-column align-items-center justify-content-center">
            <img src="assets/images/doctor_meditating_500x500.png" alt="Meditating Doctor" width="108" height="112" />
            <div className="text-center text-xl">You don't have data for the selected period</div>
          </div>
        ) : (
          <div className="mt-3">{children}</div>
        )}
      </BlockUI>
    </div>
  );
};

export default ChartCard;
