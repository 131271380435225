import React from 'react';
import CashFlow from './CashFlowCards/index';
import Counters from './Counters';
import PageWrap from '../../../components/Wraps/PageWrap/PageWrap';
import CreateTeleplanClaimsCard from './CreateRecordCards/CreateTeleplanClaimsCard';
import CreatePrivateInvoiceCard from './CreateRecordCards/CreatePrivateInvoiceCard';
import CreatePatientCard from './CreateRecordCards/CreatePatientCard';
import CreateWSBCCard from './CreateRecordCards/CreateWSBCCard';
import MSPEligibility from './MSPEligibility';

const Dashboard = () => {
  return (
    <PageWrap>
      <div className="flex flex-column gap-2 w-full" style={{ maxWidth: '1500px' }}>
        <CashFlow />

        <div className="grid">
          <div className="col-12 lg:col-6">
            <div className="grid">
              <CreateTeleplanClaimsCard />
              <CreatePrivateInvoiceCard />
              <CreatePatientCard />
              <CreateWSBCCard />
            </div>
          </div>

          <div className="col-12 lg:col-6">
            <div className="flex flex-column gap-3">
              <Counters />
              <MSPEligibility />
            </div>
          </div>
        </div>
      </div>
    </PageWrap>
  );
};

export default Dashboard;
